const flowModule = {
  title: 'Fluxos com formulário',
  new: 'Criar fluxo',
  header: {
    filter: {
      label: 'Nome do fluxo',
      placeholder: 'Busque por um fluxo',
      clear: 'Limpar',
      button: 'Filtrar',
    },
  },
  list: {
    loading: 'Carregando lista de fluxos...',
    empty:
      'Crie fluxos conectando formulários à modelos para criar um processo de assinatura automático',
    usedTemplate: 'Modelo utilizado:',
    usedForm: 'Formulários utilizados:',
    undefined: 'Não definido',
    enabled: 'Ativo',
    disabled: 'Inativo',
    flowInfo: 'Ver informações do fluxo',
    private: 'Privado',
    flowLength: '{count} Fluxo | {count} Fluxos',
    error: 'Ocorreu um erro na listagem dos fluxos',
    tab: {
      flow: 'Fluxos',
      folders: 'Pastas',
    },
    actions: {
      copyLink: 'Copiar Link',
      copyFormLink: 'Copiar link do formulário',
      options: 'Opções',
      editLink: 'Edite para ativar',
      privacy: 'Permissões',
      disableToEdit: 'Desativar para editar',
      removeFromFolder: 'Remover fluxo da pasta',
      info: 'Informações do fluxo',
    },
    notifications: {
      disable: 'Fluxo desativado com sucesso!',
      delete: 'Fluxo excluído com sucesso!',
      duplicated: 'Fluxo duplicado com sucesso!',
      removedFromFolder: 'Fluxo removido da pasta com sucesso!',
      failedToRemoveFromFolder: 'Não foi possível remover da pasta',
      bulkDisable: 'Fluxos desativados com sucesso!',
      bulkDelete: 'Fluxos excluídos com sucesso!',
      bulkDuplicate: 'Fluxos duplicados com sucesso!',
    },
    table: {
      name: 'Nome',
      folder: 'Pasta',
      createdAt: 'Data de Criação',
      enabled: 'Status',
      action: 'Ações',
      collapse: {
        template: 'Modelo utilizado',
        dataSource: 'Formulário {0} utilizado',
      },
    },
    card: {
      folder: 'Pasta',
      usedTemplate: 'Modelo utilizado',
      usedForms: 'Formulários utilizados',
      noData: 'Não informado',
      active: 'Ativo',
      inactive: 'Inativo',
    },
  },
  folders: {
    table: {
      name: 'Nome',
      flowNumber: 'Número de fluxos',
      createdAt: 'Data de Criação',
      actions: 'Ações',
      newFolder: 'Nova pasta',
      folderLength: '{count} Pasta | {count} Pastas',
    },
    actions: {
      rename: 'Renomear',
      delete: 'Excluir',
    },
    emptyList: {
      title: 'Você ainda não possui nenhuma pasta',
      description: 'Assim que você criar sua pasta, ela será listada aqui junto com as demais.',
      actionText: 'Nova pasta',
    },
    emptyFlowList: {
      title: 'Sua pasta está vazia',
      description:
        'Você pode criar um novo fluxo diretamente dentro dessa pasta ou pode buscar por um fluxo já existente.',
      searchExistingFlows: 'Buscar fluxo existente',
    },
    create: {
      modal: {
        placeholder: 'Nome da pasta',
        title: 'Nova pasta',
        selectedFlows:
          'Ao criar uma nova pasta com fluxos pré-selecionados, eles serão automaticamente movidos para dentro dela.',
      },
      errorMessage: 'Não foi possível criar a pasta',
      successMessage: 'Pasta criada com sucesso',
    },
    searchExistingFlows: {
      modal: {
        title: 'Mover fluxos para pasta',
        description: 'Selecione ou busque quais fluxos serão movidos para essa pasta.',
        goToFlows: 'Ir para Fluxos',
        move: 'Mover | Mover {count} fluxo | Mover {count} fluxos',
        empty: 'Nenhum resultado encontrado',
        placeholder: 'Busque por um fluxo',
      },
      errorMessage: 'Não foi possível mover os fluxos',
      successMessage: 'Fluxos movidos com sucesso!',
    },
    moveFlowsToFolder: {
      modal: {
        title: 'Mover fluxos',
        description: 'Selecione a pasta de destino para mover o fluxo.',
        move: 'Mover',
        empty: 'Nenhum resultado encontrado',
        placeholder: 'Busque por uma pasta',
        newFolder: 'Nova pasta',
        emptyFolder:
          'Você ainda não possui nenhuma pasta criada para mover seus fluxos. Crie uma clicando no botão abaixo.',
      },
      errorMessage: 'Não foi possível mover os fluxos',
      successMessage: 'Fluxos movidos com sucesso!',
    },
    rename: {
      modal: {
        title: 'Renomear pasta',
        placeholder: 'Nome da pasta',
      },
      errorMessage: 'Não foi possível renomear a pasta',
      successMessage: 'Pasta renomeada com sucesso',
    },
    delete: {
      title: 'Excluir pasta?',
      description: 'Os fluxos inseridos nessa pasta irão voltar para a aba de Fluxos gerais.',
      errorMessage: 'Não foi possível excluir a pasta',
      successMessage: 'Pasta excluída com sucesso',
    },
    error: {
      fetch: 'Ocorreu um erro ao buscar os fluxos. Tente novamente',
    },
  },
  filters: {
    activator: 'Filtros | Filtros ({count}) | Filtros ({count})',
    apply: 'Aplicar',
    clear: 'Limpar filtros',
    dateRangeLabel: 'Data de criação',
    dateRangePlaceholder: 'Tudo',
    search: 'Busque por um fluxo',
    statusLabel: 'Status',
    status: {
      all: 'Todos',
      disabled: 'Inativos',
      enabled: 'Ativos',
    },
    title: 'Filtrar fluxos',
  },
  modalAlert: {
    header: 'Atenção',
    title: 'Desative os fluxos conectados',
    description: 'Para editar ou excluir desative os {0} conectados a este item.',
    flows: 'fluxos',
  },
  modalDelete: {
    header: 'Excluir item',
    title: 'Tem certeza que deseja excluir este item?',
    hasPendingProcesses:
      'Atenção: Ao remover o modelo, os dados em aprovação serão perdidos! Deseja continuar?',
  },
  modalFormFlowDelete: {
    header: 'Excluir fluxo?',
    title:
      'O relatório e as aprovações pendentes deste fluxo também serão excluídos. Para salvar uma cópia das respostas do fluxo, acesse o relatório e baixe-o antes de excluir.',
  },
  modalDisable: {
    header: 'Desativar fluxo',
    title:
      'Se você desativar o fluxo ele não poderá ser utilizado, mas você poderá ativá-lo a qualquer momento.',
    hasPendingProcesses: {
      header: 'Fluxo em Aprovação',
      title:
        'Este fluxo contém respostas pendentes de aprovação. Aprove ou reprove as respostas deste fluxo antes de desativá-lo.',
      action: 'Ir para fluxo em aprovação',
    },
    hasWaitingSecondFormProcesses: {
      header: 'Fluxo com respostas pendentes',
      title:
        'Este fluxo contém respostas pendentes do segundo formulário. Aprove ou reprove as respostas deste fluxo antes de desativá-lo.',
      action: 'Ir para respostas pendentes',
    },
  },
  modalPrivacy: {
    title: 'Definir permissões de acesso do fluxo ',
    selectAccessType: {
      label: 'Selecione o tipo de acesso do fluxo',
      public: 'Acesso para todos os usuários da conta',
      private: 'Acesso apenas para usuários selecionados',
    },
    selectMembers: {
      label: 'Selecione os usuários que terão acesso ao fluxo',
      admin: 'Administrador',
      adminInfo: 'Administradores da conta sempre terão acesso as pastas por padrão',
      operator: 'Operador do fluxo',
      operatorInfo: 'Este usuário não pode ser removido por estar vinculado como operador do Fluxo',
    },
    notification: {
      updated: 'Definições de permissões atualizadas com sucesso.',
      error: 'Não foi possível atualizar as definições de permissões',
    },
  },
  removeFlowsFromFolderModal: {
    header: 'Remover fluxos da pasta?',
    title:
      'Ao remover os fluxos selecionados dessa pasta, eles voltarão a ser exibidos na aba de Fluxos.',
  },
  modalCopyLink: {
    title: 'Copiar link',
    description:
      'Envie este link para a pessoa que deve preencher o formulário. Após preenchido, um novo documento será gerado e enviado para a assinatura automaticamente.',
    copy: 'Copiar',
    popper: 'Copiado!',
    popperError: 'Não foi possível copiar, por favor copie manualmente.',
    link: 'Abrir link deste fluxo',
    close: 'Fechar',
  },
  setup: {
    loading: 'Carregando informações do fluxo...',
  },
  modalEdit: {
    header: 'Editar item',
    title: 'Tem certeza que deseja editar este item?',
    hasPendingProcesses:
      'Atenção: Ao editar o modelo, os dados em aprovação serão perdidos! Deseja continuar?',
  },
  modalEditEnabled: {
    header: 'Editar fluxo?',
    title: 'Para editar este fluxo será preciso desativá-lo. Você deseja continuar edição?',
    action: 'Desativar para editar',
    notification: {
      error: 'Ocorreu um erro ao desativar o fluxo',
      success: 'Fluxo desativado com sucesso!',
    },
  },
  show: {
    enabled: 'Habilitada',
    disabled: 'Desabilitada',
    activated: 'Ativado',
    deactivated: 'Desativado',
    disableForEdit: 'Desativar para editar',
    editToActivate: 'Editar para ativar',
    ordernationEnabled: 'Ordenação ativada',
    ordernationDisabled: 'Ordenação desativada',
    message: 'Mensagem',
    modelField: 'Campo do modelo: ',
    formField: 'Campo do formulário: ',
    generalInfo: {
      firstForm: 'Formulário 1',
      secondForm: 'Formulário 2',
      approvation: 'Aprovação',
      secondFormEmail: 'E-mail do segundo formulário',
      template: 'Modelo',
      membershipName: 'Operador do fluxo',
      flowMemberships: 'Responsáveis pela aprovação',
    },
    signerInfo: {
      title: 'Signatários',
      email: 'E-mail',
      name: 'Nome completo',
      foreignerSigner: 'Signatário estrangeiro',
      signer: 'Signatário',
      phoneNumber: 'Nùmero do celular',
      birthday: 'Data de nascimento',
      send: 'Envio',
      auth: 'Autenticações',
      flowSigner: 'Do formulário',
      fixed: 'Fixo',
      refusable: 'Recusa de assinatura',
    },
    storageFile: {
      title: 'Armazenamento dos arquivos',
      documentName: 'Nome do documento',
      storage: 'Pasta onde será armazenado',
    },
    configInfo: {
      title: 'Configurações',
      deadlineIn: 'Data limite de assinatura',
      locale: 'Idioma',
      remindInterval: 'Lembretes automáticos',
      autoClose: 'Finalização do documento',
    },
    tabs: {
      config: 'Configurações',
      fields: 'Campos do modelo e formulário',
    },
  },
  emptyList: {
    title: 'Crie seu primeiro fluxo com formulário',
    description:
      'Assim que você criar um fluxo, ele será listado aqui junto com os demais fluxos criados.',
  },
  emptySearch: {
    title: 'Nenhum resultado encontrado',
    description: 'Experimente alterar usa pesquisa ou filtro para achar o que está procurando.',
    button: 'Mostrar todos os fluxos',
    folderButton: 'Mostrar todas as pastas',
  },
  bulkActions: {
    totalSelected: '{count} item selecionado | {count} itens selecionados',
    removeFromFolder: 'Remover da pasta',
    pendingProcessesModal: {
      title: 'Fluxos com pendências',
      description:
        'A sua seleção contém fluxos que ainda possuem respostas e/ou aprovações pendentes. Resolva essas pendências para poder desativar os fluxos.',
      pendingProcesses: {
        title: 'Aprovações pendentes',
        link: 'Ver aprovações pendentes',
      },
      waitingSecondFormProcesses: {
        title: 'Respostas pendentes',
        link: 'Ver respostas pendentes',
      },
    },
    deleteModal: {
      title: 'Excluir fluxos?',
      description:
        'Os relatórios e as aprovações pendentes desses fluxos também serão excluídos. Para salvar cópias das respostas dos fluxos, acesse os relatórios e baixe-o antes de exclui-los.',
    },
    disableModal: {
      title: 'Desativar fluxos?',
      description:
        'Se você desativar os fluxos eles não poderão ser utilizados, mas você poderá ativá-los a qualquer momento.',
    },
    duplicateModal: {
      title: 'Duplicar fluxos?',
      description: 'Os fluxos selecionados serão duplicados. Deseja continuar?',
    },
    error: {
      disable: 'Ocorreu um erro ao desabilitar os fluxos. Tente novamente',
      delete: 'Ocorreu um erro ao excluir os fluxos. Tente novamente',
      duplicate: 'Ocorreu um erro ao duplicar os fluxos. Tente novamente',
    },
  },
  view: {
    list: 'Lista',
    grid: 'Grade',
  },
};

export default flowModule;
