const routes = [
  {
    view: 'AuthTermsView',
    component: () => import('../views/AuthTermsView.vue'),
  },
  {
    view: 'AuthSignerTermsView',
    component: () => import('../views/AuthSignerTermsView/AuthSignerTermsView.vue'),
  },
  {
    view: 'AuthLoginView',
    component: () => import('../views/AuthLoginView/AuthLoginView.vue'),
  },
  {
    path: '/saml/signin',
    name: 'samlSignin',
    view: 'AuthSsoLoginView',
    component: () => import('../views/AuthSsoLoginView/AuthSsoLoginView.vue'),
  },
  {
    view: 'AuthResetPasswordView',
    component: () => import('../views/AuthResetPasswordView.vue'),
  },
  {
    path: '/signup',
    view: 'AuthRegisterView',
    component: () => import('../views/AuthRegisterView.vue'),
  },
  {
    view: 'AuthRecoverPasswordView',
    component: () => import('../views/AuthRecoverPasswordView.vue'),
  },
  {
    view: 'AuthMultiFactorAuthenticationContainer',
    component: () => import('../containers/AuthMultiFactorAuthenticationContainer.vue'),
  },
  {
    view: 'AuthDisableMFA',
    component: () => import('../views/AuthDisableMFA.vue'),
  },
];

export default routes;
