const auth = {
  navbar: {
    signin: {
      text: '¿Ya tienes una cuenta?',
      button: 'Iniciar sesión',
    },
    signup: {
      text: '¿No tienes una cuenta?',
      button: 'Regístrate',
    },
  },
  multiFactor: {
    title: 'Ingresa el código de verificación',
    text: 'Ingresa a tu aplicación de autenticación para obtener el código',
    tooltip: 'Utiliza Google Authenticator u otra app para recibir el código actualizado',
    button: 'Iniciar sesión',
    help: 'No puedo acceder a mi aplicación',
    errors: {
      default: 'Error al realizar la autenticación. Inténtalo de nuevo más tarde',
      wrongValue: 'Código incorrecto. Inténtalo de nuevo.',
      sms: 'No se pudo enviar el código por SMS. Inténtalo de nuevo.',
    },
    backupCodes: {
      title: 'Usar código de recuperación',
      text: 'Ingresa uno de los códigos de recuperación proporcionados por Clicksign',
      question: '¿Dudas?',
      supportLink: 'Contacta a soporte',
      sendSMS: 'Recibir código por SMS',
      smsSuccess: 'Código enviado por SMS con éxito',
      tryResend: 'Enviar código por SMS {time}',
    },
    disableMFA: {
      title: 'La verificación de dos pasos ha sido deshabilitada',
      text: 'Usaste un código de recuperación para ingresar a Clicksign. Por seguridad, la verificación de dos pasos ha sido deshabilitada.',
      enableAgain: 'Habilitar de nuevo',
      notEnable: 'Ingresar sin habilitar',
      howEnable: 'Cómo habilitar la verificación en dos pasos',
    },
  },
  signin: {
    forgotPassword: '¿Olvidaste tu contraseña?',
    submit: 'Iniciar sesión',
    back: 'Regresar',
    goToDesk: {
      text: 'O ingresa a {0}',
      deskApp: 'Clicksign Classic',
    },
    signup: {
      text: '¿No tienes una cuenta? {0}',
      link: 'Regístrate',
    },
    form: {
      email: {
        label: 'Correo electrónico',
        placeholder: 'nombre@ejemplo.com',
      },
      password: {
        label: 'Contraseña',
        placeholder: 'Ingresa tu contraseña',
      },
    },
    oauth: {
      google: 'Google',
      microsoft: 'Microsoft',
      saml: 'Single Sign On',
    },
    saml: {
      title: 'Single Sign On',
      backToLogin: 'Inicia sesión con correo electrónico y contraseña',
      button: 'Continuar',
    },
    errors: {
      default: 'Error al iniciar sesión. Inténtalo de nuevo más tarde',
    },
  },
  resetPassword: {
    title: 'Hola, {0}',
    subtitle: 'Define tu nueva contraseña',
    button: 'Guardar nueva contraseña',
    message: 'La contraseña se cambió con éxito',
    password: 'Contraseña',
  },
  terms: {
    title: 'Actualización de {0} y {1}',
    tou: 'Términos de Uso',
    lgpd: 'Política de Privacidad - LGPD',
    privacy: 'Aviso de Privacidad',
    textOld:
      'Clicksign ha actualizado los {0}, incluyendo las nuevas funcionalidades de la plataforma, y la {1}, conforme a todos los requisitos necesarios para cumplir con la Ley de Protección de Datos (LGPD). Estamos comprometidos a proteger la privacidad y la seguridad de nuestros usuarios',
    text: 'Clicksign ha actualizado los {0} y la {1}, teniendo en cuenta las nuevas funcionalidades de la plataforma y nuestro compromiso con la transparencia en el tratamiento de tus datos.',
    checkOld: 'He leído y acepto los {0} y {1} de Clicksign.',
    check: 'He leído y acepto los {0} de Clicksign.',
    message: 'Conozco las disposiciones del {0} de Clicksign.',
    close: 'Cerrar',
    confirm: 'Continuar',
  },
  signerAreaTerms: {
    title: 'Ingresa a tu Área de firma',
    subtitle: 'Aquí podrás ver todos tus documentos pendientes en un solo lugar.',
  },
  accessTermEnabled: {
    text: 'Acepto los {0} y la {1}',
    useTerms: 'Términos de Uso',
    privacity: 'Política de Privacidad',
  },
  userSignup: {
    title: 'Regístrate gratis',
    withEmail: 'Continuar con correo electrónico',
    oauth: {
      google: 'Google',
      microsoft: 'Microsoft',
      withGoogle: 'Continuar con Google',
      withMicrosoft: 'Continuar con Microsoft',
    },
    form: {
      orEnter: 'o',
      button: 'Confirmar',
      name: {
        label: 'Nombre completo',
        placeholder: 'Ingresa tu nombre',
      },
      email: {
        label: 'Correo electrónico',
        placeholder: 'nombre@ejemplo.com',
      },
      password: {
        label: 'Contraseña',
      },
      accessTermEnabled: {
        text: 'Al confirmar, aceptas los {0} y la {1}',
        useTerms: 'Términos de Uso',
        privacity: 'Política de Privacidad',
      },
    },
    login: {
      text: '¿Ya estás registrado?',
      link: 'Ingresa a tu cuenta',
    },
    footerInfo: {
      text: '¿Solo necesitas firmar un documento?',
      button: 'Descubre cómo',
    },
    advertisement: {
      text: 'Descubre las novedades de Clicksign',
    },
  },
  recoverPassword: {
    title: '¿Olvidaste tu contraseña?',
    text: 'Ingresa el correo electrónico registrado en Clicksign. Te enviaremos un enlace para restablecer tu contraseña.',
    submit: 'Enviar',
    link: 'Volver al inicio de sesión',
    form: {
      email: {
        label: 'Correo electrónico',
        placeholder: 'nombre@ejemplo.com',
        errors: {
          tooManyRequests: 'Límite de envíos excedido. Espera 1 minuto e inténtalo de nuevo.',
        },
      },
    },
    success: {
      title: '¡Listo!',
      text: 'Hemos enviado un correo a {0} con un enlace y las instrucciones para que puedas crear una nueva contraseña.',
      button: 'Volver al inicio de sesión',
    },
  },
  pending: {
    caption: 'Hemos enviado un correo a',
    body: 'Revisa tu correo y sigue las instrucciones para completar tu registro.',
  },
  passwordFullRequirements: {
    title: 'Crea una contraseña',
    minLength: 'Con al menos {min} caracteres.',
    containsNumber: 'Con números',
    upperAndDown: 'Con mayúsculas y minúsculas',
    containsSpecial: 'Con caracteres especiales (!&@)',
    hasNoSequentialCharacters: 'No escribas combinaciones consecutivas (abc, 123, cba)',
    cannotContainsName: 'No utilices tu nombre',
  },
  sessionExpired: 'Tu sesión expiró. Por favor, inicia sesión de nuevo.',
  sso: {
    greeting: 'Hola, {name}!',
    disclaimer: 'Este es tu acceso al ambiente exclusivo de {company} en Clicksign.',
    tip: {
      title: 'Sugerencia:',
      content:
        'agrega esta página a tus favoritos para acceder de manera rápida y fácil cuando lo necesites.',
    },
    companyLogoAlt: 'Logo de {company}',
    form: {
      title: 'Single Sign On {company}',
      action: 'Continuar',
      problems: '¿Tienes problemas para iniciar sesión?',
      contactSupport: 'Contacta al equipo de soporte',
      email: {
        label: 'Correo electrónico',
        placeholder: 'nombre@ejemplo.com',
      },
    },
    signinError: 'No encontramos la cuenta. Verifica los datos e inténtalo de nuevo.',
  },
  recaptcha: {
    script_load_error: 'Error al cargar el script de reCAPTCHA.',
  },
};

export default auth;
