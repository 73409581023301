<script setup>
import { useVModel } from '@vueuse/core';
import { useI18n } from '@/locales';
import { momentFormatDate } from '@/utils/date';
import { computed, ref } from 'vue';
import { FormFlowVerticalMenuActions } from '@flow/components/form-flow';
import { FLOW_CONTEXT_NAMES_CONSTANTS, FORM_FLOW_MODAL_CONSTANTS } from '@flow/constants';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  headers: {
    type: Array,
    default: () => [],
  },
  gridTemplateColumns: {
    type: String,
    default: '',
  },
  index: {
    type: Number,
    default: 0,
  },
  account: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: true,
  },
  disabledActions: {
    type: Array,
    default: () => [],
  },
  context: {
    type: String,
    default: FLOW_CONTEXT_NAMES_CONSTANTS.flowList,
  },
  checkable: {
    type: Boolean,
    default: false,
  },
  selectedRows: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:selectedRows']);

const { t } = useI18n();

const selectedRows = useVModel(props, 'selectedRows', emit);

const collapseOpenned = ref(false);

const showCollapseButton = computed(
  () => props.item?.dataSources?.length > 0 || props.item?.template
);
const isSelected = computed(() => !!selectedRows.value.find((row) => row.id === props.item.id));

const handleCollapse = () => {
  collapseOpenned.value = !collapseOpenned.value;
};
</script>

<template>
  <div>
    <XTableRow
      v-model="selectedRows"
      :class="[isSelected && $style.isSelected]"
      :item="item"
      :headers="headers"
      :grid-template-columns="gridTemplateColumns"
      :checkable="checkable"
    >
      <template #column="{ column, columnValue }">
        <template v-if="column.value === 'name'">
          <template v-if="loading">
            <XSkeletonLoader height="24px" />
          </template>
          <span
            v-else
            :class="[$style.text, !item.enabled && $style.desactiveText]"
          >
            {{ columnValue }}
          </span>
        </template>
        <template
          v-if="column.value === 'folder' && context === FLOW_CONTEXT_NAMES_CONSTANTS.flowList"
        >
          <template v-if="loading">
            <XSkeletonLoader height="24px" />
          </template>
          <template v-else>
            <span v-if="!columnValue?.name"> -- </span>
            <div
              v-else
              :title="columnValue.name"
              :class="$style.folderName"
            >
              <XLink
                :class="[$style.folderLink, $style.text]"
                :to="columnValue.links.self"
                plain
                no-padding
              >
                <XIcon
                  :class="$style.folderIcon"
                  icon="folder"
                  left
                />
                {{ columnValue.name }}
              </XLink>
            </div>
          </template>
        </template>
        <template v-if="column.value === 'createdAt'">
          <template v-if="loading">
            <XSkeletonLoader height="24px" />
          </template>
          <span
            v-else
            :class="!item.enabled && $style.desactiveText"
          >
            {{ momentFormatDate(columnValue) }}
          </span>
        </template>
        <template v-if="column.value === 'enabled'">
          <template v-if="loading">
            <XSkeletonLoader height="24px" />
          </template>
          <XBadge
            v-else
            :icon="item.enabled ? 'check-circle' : 'minus-octagon'"
            :color="item.enabled ? 'var(--color-feedback-success-300)' : 'var(--color-neutral-500)'"
          >
            {{ item.enabled ? t('flowModule.list.enabled') : t('flowModule.list.disabled') }}
          </XBadge>
        </template>
        <template v-if="column.value === 'action'">
          <template v-if="loading">
            <XSkeletonLoader height="24px" />
          </template>
          <div
            v-else
            :class="$style.itemsColAction"
          >
            <XButton
              v-if="item.enabled"
              border-radius="circle"
              design="text"
              size="xs"
              data-ph-capture-attribute-action="copy_form_link"
              @click="$emit('open-modal', FORM_FLOW_MODAL_CONSTANTS.formFlowCopyLinkModal, item)"
            >
              <XIcon icon="link" />
            </XButton>

            <FormFlowVerticalMenuActions
              :current-user="account"
              :flow="item"
              :disabled-actions="disabledActions"
              @click-option="($event) => $emit('click-option', $event)"
            />

            <XButton
              v-if="showCollapseButton"
              border-radius="circle"
              design="text"
              size="xs"
              @click="handleCollapse"
            >
              <XIcon :icon="collapseOpenned ? 'chevron-up' : 'chevron-down'" />
            </XButton>
          </div>
        </template>
      </template>
    </XTableRow>
    <div
      v-if="collapseOpenned"
      :class="$style.collapse"
    >
      <div
        v-if="item?.template"
        :class="$style.collapseItem"
      >
        <span :class="$style.collapseText">
          {{ $t('flowModule.list.table.collapse.template') }}
        </span>
        <span :class="$style.collapseText"> {{ item.template.name }} </span>
      </div>
      <div
        v-for="(dataSource, dataIndex) in item.dataSources"
        :key="dataIndex"
        :class="$style.collapseItem"
      >
        <span :class="$style.collapseText">
          {{ $tc('flowModule.list.table.collapse.dataSource', dataIndex + 1, [dataIndex + 1]) }}
        </span>
        <XLink
          :to="dataSource.links.self"
          target="_blank"
          no-padding
          small
        >
          <span> {{ dataSource.name }} </span>
        </XLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.itemsColAction {
  display: flex;
  justify-content: flex-end;
}

.collapse {
  display: flex;
  flex-direction: column;
  padding: var(--space-regular);
  gap: var(--space-small-x);
  background-color: var(--color-neutral-50);

  .collapseItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .collapseText {
      font-size: var(--font-size-small);
      color: var(--color-neutral-800);
    }
  }
}

.folderName {
  display: flex;
  align-items: center;

  .folderLink {
    font-weight: var(--font-weight-regular);
  }

  .folderIcon {
    color: var(--color-brand-primary-400);
  }
}

.desactiveText {
  color: var(--color-neutral-600);
}

.text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.isSelected {
  background-color: var(--color-brand-primary-50);
}
</style>
