const liveness = {
  title: 'Dynamic selfie',
  carousel: {
    step1: {
      title: 'Dynamic selfie',
      text: 'We need to take a picture of you to prove you are a real person',
    },
    step2: {
      title: 'Your picture will be attached to the document',
      text: {
        first: 'Be dressed',
        second: 'Avoid glasses and accessories',
        third: 'Avoid laminated documents',
        fourth: 'Find a clear place',
      },
    },
    step3: {
      title: 'Activate the camera',
      text: "When asked, tap 'allow' to begin image capture.",
    },
  },
  cafModal: {
    title: 'Almost there! Take a picture of your face to finish the validation.',
    error: 'We found a problem',
    content: {
      loading: 'We send the photo for review {0} Wait a moment...',
      keepPosition: 'Keep position',
      success: 'Photo taken successfully!',
      error: "We don't recognize the face",
      sdkError: 'Something went wrong please try again.',
    },
    button: {
      success: 'Next',
      error: 'Try Again',
      sendAndNext: 'Submit and continue',
    },
    flipCamera: 'Turn camera',
  },
  attemptsError: {
    title: 'Attempt limit reached',
    subtitle: 'Notify the sender of the document',
  },
  result: {
    remaining: 'Notify the sender of the document | Attempts remaining: {count}',
    takeAnother: 'Take another',
    isOkTitle: 'Is the photo okay?',
    isOkSub:
      'Your photo will be protected by a watermark. By sending it, you authorize the registration of the image in the final document',
  },
  cameraError: 'Camera not found.',
  actions: {
    next: 'Next',
    tryAgain: 'Try again',
    goBack: 'Go back',
    allowCamera: 'Activate camera',
    takePicture: 'Take a picture',
  },
  knowMore: 'Know more',
  consentLabel:
    'By clicking "Submit", you authorize the registration of your image in the document log.',
  helpModal: {
    whatIsLivenessTitle: 'What is Dynamic selfie?',
    whatIsLivenessDescription:
      "It's authentication that allows you to validate that whoever signs is a real person through the capture of facial expressions.",
    whyLivenessTitle: 'Why sign with Dynamic selfie?',
    whyLivenessDescription:
      'This authentication reduces the chance of fraud, making the electronic signature even more secure.',
    authMethod: '*This form of authentication was chosen by the sender of this document.',
    title: 'Dynamic selfie',
    knowMore: {
      text1: 'Want to know more? Check our',
      text2: 'and',
      link1: 'Privacy Policy',
      link2: 'Help Central.',
    },
  },
  analyzingModal: 'Analyzing a photo of your face.',
  verifyError: {
    reproved: {
      title: 'Photo not recognized.',
      description: 'The document will not be signed. {0} Contact the sender.',
    },
    refused: {
      title: '@:steps.liveness.verifyError.reproved.title',
      description: '@:steps.liveness.verifyError.reproved.description',
    },
  },
};

export default liveness;
