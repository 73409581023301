const localStorage = {
  setLocalStorageKey(key, content) {
    if (!window.localStorage) return;
    window.localStorage.setItem(key, content);
  },

  getLocalStorageKey(key) {
    return !window.localStorage ? undefined : JSON.parse(window.localStorage.getItem(key));
  },

  removeLocalStoragekey(key) {
    if (!window.localStorage) return;
    window.localStorage.removeItem(key);
  },
};
export default localStorage;
