const auth = {
  navbar: {
    signin: {
      text: 'Já possui uma conta?',
      button: 'Entrar',
    },
    signup: {
      text: 'Não possui uma conta?',
      button: 'Cadastre-se',
    },
  },
  multiFactor: {
    title: 'Insira seu código de verificação',
    text: 'Acesse seu aplicativo de autenticação para obter o código ',
    tooltip: 'Ultilize o Google Authenticator ou outro app para receber o código atualizado',
    button: 'Entrar',
    help: 'Não consigo acessar meu aplicativo',
    errors: {
      default: 'Erro ao realizar autenticação. Tente novamente mais tarde',
      wrongValue: 'Código incorreto. Tente novamente.',
      sms: 'Falha ao enviar código via SMS. Tente novamente.',
    },
    backupCodes: {
      title: 'Usar código de recuperação',
      text: 'Digite um dos códigos de recuperação disponibilizados pela Clicksign',
      question: 'Dúvidas?',
      supportLink: 'Fale com o suporte',
      sendSMS: 'Receber código via SMS',
      smsSuccess: 'Código enviado via SMS com sucesso',
      tryResend: 'Enviar código via SMS {time}',
    },
    disableMFA: {
      title: 'Verificação em duas etapas foi desabilitada',
      text: 'Você usou um código de recuperação para acessar a Clicksign. Por segurança, a verificação em duas etapas foi desabilitada.',
      enableAgain: 'Habilitar novamente',
      notEnable: 'Entrar sem habilitar',
      howEnable: 'Como habilitar verificação em duas etapas',
    },
  },
  signin: {
    forgotPassword: 'Esqueceu sua senha?',
    submit: 'Entrar',
    back: 'Voltar',
    goToDesk: {
      text: 'Ou entre na {0}',
      deskApp: 'Clicksign Classic',
    },
    signup: {
      text: 'Não possui uma conta? {0}',
      link: 'Cadastre-se',
    },
    form: {
      email: {
        label: 'E-mail',
        placeholder: 'nome@exemplo.com.br',
      },
      password: {
        label: 'Senha',
        placeholder: 'Digite sua senha',
      },
    },
    oauth: {
      google: 'Google',
      microsoft: 'Microsoft',
      saml: 'Single Sign On',
    },
    saml: {
      title: 'Single Sign On',
      backToLogin: 'Faça login com e-mail e senha',
      button: 'Continuar',
    },
    errors: {
      default: 'Erro ao realizar o login. Tente novamente mais tarde',
    },
  },
  resetPassword: {
    title: 'Olá, {0}',
    subtitle: 'Defina sua nova senha',
    button: 'Salvar nova senha',
    message: 'Senha alterada com sucesso',
    password: 'Senha',
  },
  terms: {
    title: 'Atualização de {0} e {1}',
    tou: 'Termos de Uso',
    // TOGGLE_NEW_TERMS_MESSAGE_ENABLED
    // TODO: remove lgpd
    lgpd: 'Política de Privacidade - LGPD',
    privacy: 'Aviso de Privacidade',
    // TOGGLE_NEW_TERMS_MESSAGE_ENABLED
    // TODO: remove textOld
    textOld:
      'A Clicksign atualizou os {0}, incluindo as novas funcionalidades da plataforma, e a {1}, pensada em todos os requisitos necessários para atender à Lei de Proteção de Dados (LGPD). Estamos empenhados em proteger a privacidade e a segurança dos nossos usuários',
    text: 'A Clicksign atualizou os {0} e o {1}, tendo em vista as novas funcionalidades da plataforma e o nosso compromisso de transparência com o tratamento dos seus dados.',
    // TOGGLE_NEW_TERMS_MESSAGE_ENABLED
    // TODO: remove checkOld
    checkOld: 'Li e concordo com {0} e {1} da Clicksign.',
    check: 'Li e concordo com os {0} da Clicksign.',
    message: 'Estou ciente do disposto no {0} da Clicksign.',
    close: 'Fechar',
    confirm: 'Prosseguir',
  },
  signerAreaTerms: {
    title: 'Acesse sua Área de assinatura',
    subtitle: 'Aqui você poderá ver todos seus documentos pendentes em um só lugar!',
  },
  accessTermEnabled: {
    text: 'Concordo com {0} e {1}',
    useTerms: 'Termos de Uso',
    privacity: 'Política de Privacidade',
  },
  userSignup: {
    title: 'Cadastre-se grátis',
    withEmail: 'Continuar com e-mail',
    oauth: {
      google: 'Google',
      microsoft: 'Microsoft',
      withGoogle: 'Continuar com o Google',
      withMicrosoft: 'Continuar com a Microsoft',
    },
    form: {
      orEnter: 'ou',
      button: 'Confirmar',
      name: {
        label: 'Nome completo',
        placeholder: 'Digite o seu nome',
      },
      email: {
        label: 'E-mail',
        placeholder: 'nome@exemplo.com.br',
      },
      password: {
        label: 'Senha',
      },
      accessTermEnabled: {
        text: 'Ao confirmar você concordará com os {0} e {1}.',
        useTerms: 'Termos de Uso',
        privacity: 'Política de Privacidade',
      },
    },
    login: {
      text: 'Já tem cadastro?',
      link: 'Acesse sua conta',
    },
    footerInfo: {
      text: 'Só precisa assinar um documento?',
      button: 'Saiba como',
    },
    advertisement: {
      text: 'Saiba das novidades da clicksign',
    },
  },
  recoverPassword: {
    title: 'Esqueceu sua senha?',
    text: 'Digite o e-mail cadastrado na Clicksign. Enviaremos um link para redefinir sua senha.',
    submit: 'Enviar',
    link: 'Voltar para o login',
    form: {
      email: {
        label: 'E-mail',
        placeholder: 'nome@exemplo.com.br',
        errors: {
          tooManyRequests: 'Limite de envio excedido. Aguarde 1 minuto e tente novamente.',
        },
      },
    },
    success: {
      title: 'Pronto!',
      text: 'Enviamos um e-mail para {0} com um link e as instruções para que você crie uma nova senha.',
      button: 'Voltar para o login',
    },
  },
  pending: {
    caption: 'Enviamos um e-mail para',
    body: 'Verifique seu e-mail, siga as instruções para concluir seu cadastro.',
  },
  passwordFullRequirements: {
    title: 'Crie uma senha',
    minLength: 'Com pelo menos {min} caracteres.',
    containsNumber: 'Com números',
    upperAndDown: 'Com maiúsculas e minúsculas',
    containsSpecial: 'Com caracteres especiais (!&@)',
    hasNoSequentialCharacters: 'Que não contenha sequência (abc, 123, cba)',
    cannotContainsName: 'Que não use seu nome',
  },
  sessionExpired: 'Sessão expirada. Faça login novamente.',
  sso: {
    greeting: 'Olá, {name}!',
    disclaimer: 'É por aqui que você acessa o ambiente exclusivo da {company} na Clicksign.',
    tip: {
      title: 'Dica:',
      content:
        'adicione esta página aos favoritos do seu navegador para acessá-la facilmente quando quiser.',
    },
    companyLogoAlt: 'Logo da {company}',
    form: {
      title: 'Single Sign On {company}',
      action: 'Continuar',
      problems: 'Problemas com o acesso?',
      contactSupport: 'Fale com o suporte',
      email: {
        label: 'E-mail',
        placeholder: 'nome@exemplo.com.br',
      },
    },
    signinError: 'Conta não localizada. Revise e tente novamente.',
  },
  recaptcha: {
    script_load_error: 'Erro ao carregar script do reCAPTCHA.',
  },
};

export default auth;
