<script setup>
import { computed, ref, watch } from 'vue';
import { useInfiniteScroll, useDebounceFn } from '@vueuse/core';
import { useStore } from '@/store';
import { XIcon } from '@clicksign/excalibur-vue2/lib';

const props = defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
  selectedRows: {
    type: Array,
    default: () => [],
  },
});
defineEmits(['close', 'handle-move', 'new-folder']);

const MIN_TEXT_LENGTH = 3;
const INFINITE_SCROLL_DISTANCE = 120;
const SEARCH_DEBOUNCE_DELAY = 400;

const store = useStore();

const loading = ref(true);
const listWrapperEl = ref(null);
const search = ref('');
const selectedFolder = ref(null);
const totalFolders = ref(0);

const foldersList = computed(() => store.getters['formFlow/getMoveFolders']);
const currentPage = computed(() => store.getters['formFlow/getMoveFoldersPagination'].page);
const totalPages = computed(() => store.getters['formFlow/getMoveFoldersPagination'].pageCount);
const isLastPage = computed(
  () => totalPages.value !== null && currentPage.value >= totalPages.value
);
const hasNotFolders = computed(() => !totalFolders.value && !loading.value && search.value === '');

const fetchMoveFoldersList = async () => {
  loading.value = true;
  const folderName = search.value.trim();

  const firstFolderId = props.selectedRows[0]?.folder?.id;
  const allSameFolder = props.selectedRows.every((flow) => flow?.folder?.id === firstFolderId);
  const excludeFolderId = allSameFolder ? firstFolderId : null;

  await store.dispatch('formFlow/fetchMoveFolders', { folderName, excludeFolderId });

  if (folderName === '') {
    totalFolders.value = foldersList.value.length;
  }

  loading.value = false;
};

const openedModal = () => {
  selectedFolder.value = null;
  search.value = '';
  store.dispatch('formFlow/resetMoveFolders');
  fetchMoveFoldersList();
};

const skipSearch = (textSearch) => {
  const trimmed = textSearch.trim();
  const textSearchIsEmpty = trimmed.length === 0;

  return trimmed.length < MIN_TEXT_LENGTH && !textSearchIsEmpty;
};

const handleSearch = async () => {
  if (skipSearch(search.value)) return;

  store.dispatch('formFlow/resetMoveFolders');
  fetchMoveFoldersList();
};

const selectFolder = (folder) => {
  selectedFolder.value = folder;
};

const debouncedSearch = useDebounceFn(handleSearch, SEARCH_DEBOUNCE_DELAY);

useInfiniteScroll(
  listWrapperEl,
  () => {
    if (loading.value || isLastPage.value) return;

    fetchMoveFoldersList();
  },
  { distance: INFINITE_SCROLL_DISTANCE }
);

watch(search, debouncedSearch);
</script>

<template>
  <XModal
    :is-open="showModal"
    :title="$t('flowModule.folders.moveFlowsToFolder.modal.title')"
    data-testid="moveFlowsToFolderModal"
    @opened="openedModal"
    @close="$emit('close')"
  >
    <template
      v-if="hasNotFolders"
      #default
    >
      <div data-testid="emptyScreen">
        <p :class="$style.textCenter">
          {{ $t('flowModule.folders.moveFlowsToFolder.modal.emptyFolder') }}
        </p>
        <div :class="$style.emptyAction">
          <XButton
            design="outlined"
            @click="$emit('new-folder')"
          >
            {{ $t('flowModule.folders.moveFlowsToFolder.modal.newFolder') }}
            <XIcon
              icon="folder-plus"
              right
            />
          </XButton>
        </div>
      </div>
    </template>
    <template
      v-else
      #default
    >
      <p>{{ $t('flowModule.folders.moveFlowsToFolder.modal.description') }}</p>
      <XTextInput
        v-model="search"
        :class="$style.search"
        :placeholder="$t('flowModule.folders.moveFlowsToFolder.modal.placeholder')"
        full-width
        grouped
        data-testid="searchExistingFoldersModalTextSearch"
      >
        <XButton
          model="plain"
          @click="handleSearch"
        >
          <XIcon icon="search" />
        </XButton>
      </XTextInput>
      <div
        ref="listWrapperEl"
        :class="$style.listWrapper"
        data-testid="moveFoldersList"
      >
        <XListItem
          v-for="(folder, index) in foldersList"
          :key="`folder-${folder.id}-${index}`"
          :active="selectedFolder?.id === folder.id"
          ellipsis
          :data-testid="`flow-${folder.id}-${index}`"
          @click="selectFolder(folder)"
        >
          {{ folder.name }}
        </XListItem>
        <template v-if="loading || !isLastPage">
          <XListItem
            v-for="index in 3"
            :key="index"
            no-clickable
            height="40px"
          >
            <XSkeletonLoader
              height="24px"
              shape="rounded"
            />
          </XListItem>
        </template>
        <template v-else-if="foldersList.length === 0 && search !== ''">
          <div
            :class="$style.empty"
            data-testid="emptySearchScreen"
          >
            <XIcon
              icon="search"
              size="2x"
            />
            <h4 :class="$style.title">
              {{ $t('flowModule.folders.moveFlowsToFolder.modal.empty') }}
            </h4>
          </div>
        </template>
      </div>
    </template>
    <template
      v-if="!hasNotFolders"
      #actions
    >
      <div
        :class="$style.actions"
        data-testid="moveFlowsToFolderModalActions"
      >
        <XButton
          design="outlined"
          @click="$emit('new-folder')"
        >
          {{ $t('flowModule.folders.moveFlowsToFolder.modal.newFolder') }}
          <XIcon
            icon="folder-plus"
            right
          />
        </XButton>
        <XButton
          :disabled="!selectedFolder"
          data-testid="moveFlowsToFolderModalMoveButton"
          @click="$emit('handle-move', { selectedFolder: selectedFolder })"
        >
          {{ $t('flowModule.folders.moveFlowsToFolder.modal.move') }}
        </XButton>
      </div>
    </template>
  </XModal>
</template>

<style lang="scss" module>
.search {
  margin-bottom: var(--space-small-xx);
}

.listWrapper {
  overflow-y: auto;
  height: 200px;
  border: var(--border-width-small) solid var(--color-neutral-400);
  border-radius: var(--border-radius-medium);

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: var(--space-regular);
  }
}

.actions {
  display: flex;
  gap: var(--space-small-xx);
}

.textCenter {
  text-align: center;
  color: var(--color-neutral-800);
}

.emptyAction {
  display: flex;
  justify-content: center;
}
</style>
