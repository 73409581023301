const plans = {
  integration: 'Integración',
  eventual: 'Eventual',
  essencial: 'Esencial',
  profissional: 'Profesional',
  empresarial: 'Empresarial',
  budget: 'Presupuesto',
  basic: 'Básico',
  standard: 'Estándar',
  ilimitado: 'Ilimitado',
  growth: 'Crecimiento',
  start: 'Inicio',
  plus: 'Plus',
  avancado: 'Avanzado',
};

const basicFeatures = {
  auth: 'Suscripción vía correo electrónico',
  order: 'Clasificación de firmas',
  handwritten: 'Firma manuscrita',
  pix: 'Autenticación por PIX',
  certificate: 'Autenticación mediante Certificado Digital',
  officialDocument: 'Autenticación mediante Documento Oficial',
  massive: 'Suscripción masiva',
};

const subscription = {
  promotion: {
    planCardHighlight: {
      title: 'Oferta exclusiva: hasta un 16% de descuento',
      description:
        'Contrate 50 ó 100 documentos al mes con condiciones especiales en la cuota mensual, por tiempo limitado.',
      action: 'Aplicar descuento',
      appliedDiscount: 'Descuento aplicado',
    },
    // TOGGLE_SMB_PRICING_TEST_ENABLED
    // TODO: remova badge, selectBadge e terms
    badge: 'Descuento por tiempo limitado',
    selectBadge: '16% off',
    terms: {
      disclaimer: '*Ofertas por tiempo limitado. {0}',
      link: 'Consulta la normativa',
    },
  },
  confirmChangePlan: {
    title: 'Confirma el cambio de plan',
  },
  successChangePlan: {
    title: 'El plan ha sido cambiado con éxito',
  },
  plan: {
    title: 'Plan',
    changePlan: '¡Nuestros planes han mejorado! Si lo prefieres, revisa los {0}',
    newPlans: 'Consulta los nuevos planes.',
    product: {
      automation: 'Automatización',
      ...plans,
    },
    commercialName: {
      customized: 'Personalizado',
      ...plans,
    },
    feature: {
      monthDocuments: 'R$ 8,50/documento | {quantity} documento/mes | {quantity} documentos/mes',
      usersPerAccount: '1 usuario en la cuenta | {quantity} usuarios en la cuenta',
      pricePerDocument: 'R$ {price} por documento finalizado',
      periodicity: '/mes',
      onDemand: 'A la medida',
      moreDocuments: 'Más de 150 documentos por mes',
    },
    comparePlans: {
      title: 'Compara los planes',
    },
    edit: {
      title: 'Confirma la modificación del plan',
      consumption: {
        title: 'Tu consumo',
        usage: 'Has usado {0} de los {1} días contratados en el plan actual.',
        charge:
          ' La facturación se realiza por la disponibilidad del servicio. {0} En caso de uso parcial, se aplicará un descuento proporcional.',
        link: 'Conoce cómo funciona nuestro cálculo.',
        invoice: 'Nota: En caso de {0} en el período actual, se generará una factura adicional.',
        surplusConsumptionLink: 'consumo excedente',
      },
      new: {
        title: 'Al confirmar, se generará una factura con el valor de:',
        newMonthlyPayment: 'Mensualidad del nuevo plan',
        discountApplied: 'Descuento aplicado',
        firstNewPayment: 'Primera mensualidad del plan actual',
        review: 'Revisa la información antes de confirmar',
      },
    },
    changeSuccess: {
      title: 'El plan ha sido cambiado con éxito',
      description: 'Ahora tu plan es {0}',
      action: 'Volver a Plan y Facturación',
      eventual: {
        variety: 'Varias autenticaciones',
        panel: 'Panel de gestión de cuenta',
        signature: 'Firma digital',
        reports: 'Reportes completos',
        storage: 'Almacenamiento de documentos',
      },
      start: {
        auth: 'Firma vía correo electrónico',
        kind: '4 tipos de autenticación',
        api: 'Integración vía API',
      },
      plus: {
        auth: 'Firma vía correo electrónico, SMS y WhatsApp',
        kind: '8 tipos de autenticación',
        audit: 'Auditoría de cuenta',
      },
      integration: {
        features: 'Todas las funcionalidades de los planes de Suscripción',
        api: 'Integración con Clicksign vía API',
      },
    },
    changeError: {
      same: 'No es posible cambiar al mismo plan ya contratado',
      generic: 'Algo salió mal, contacta con el',
      exceded:
        'Has excedido el número de cambios de plan permitidos en este período. Contacta con el equipo de soporte a través del chat o ',
    },
  },
  planFeature: {
    // TOGGLE_SMB_PRICING_TEST_ENABLED
    // TODO: volte o locale para '{amount} documentos por mes'
    select: '{amount} documentos/mes',
    included: 'Incluye:',
    essentials: 'Funciones esenciales:',
    additionals: ' Más funciones, sin costo adicional:',
    customizable: 'Funciones personalizables:',
    startsWith: {
      users: 'Hasta {users} usuarios',
      apiIntegration: 'Integración a través de API o Zapier',
      periodicity: 'Contratación mensual',
    },
    start: {
      basicFeatures: {
        ...basicFeatures,
        whatsApp: 'Aceptar vía WhatsApp',
      },
    },
    plus: {
      basicFeatures: {
        ...basicFeatures,
        whatsApp: 'Aceptar vía WhatsApp',
      },
      additionalFeatures: {
        whatsAppSign: 'Firma a través de WhatsApp',
        smsSign: ' Firma a través de SMS',
        authFacial: 'Autenticación por Biometría',
        authDocumentSelfie: 'Autenticación por Selfie con Documento',
        authSelfie: 'Autenticación por Selfie Dinámica',
        customSender: 'Remitente Personalizado',
        customization: 'Personalización con logotipo y colores',
      },
    },
    avancado: {
      startsWith: {
        users: 'Usuarios ilimitados',
        apiIntegration: 'Integración a través de API o Zapier',
        periodicity: 'Contratación anual o mensual',
      },
      basicFeatures: {
        ...basicFeatures,
        packages: 'Paquetes de aceptación',
      },
      customFeatures: {
        whatsAppSign: 'Firma a través de WhatsApp',
        authFacial: 'Autenticación por Biometría',
        authDocumentSelfie: 'Autenticación por Selfie con Documento',
        authSelfie: 'Autenticación por Selfie Dinámica',
        customSender: 'Remitente personalizado',
        customization: 'Personalización con logotipo y colores',
        presential: 'Firma presencial',
        sso: 'Single Sign On (SSO)',
        api: 'Plantillas a través de API',
        embedded: 'Widget incrustado',
        automation: 'Automatización',
      },
    },
  },
  // TO-DO: Move blockedFunctionality block below to a new
  // single file in this section Subscription, called Premium
  blockedFunctionality: {
    title: 'Actualización de plan',
    button: 'Actualizar',
    availableOnPlusPlan: 'Disponible desde el plan Plus.',
    availableOnCustomPlan: 'Disponible en el plan Avanzado.',
    imageAlt: 'Mujer usando una laptop',
    customization: {
      title: 'Haz una actualización para personalizar',
      firstText:
        'La experiencia de firma de tus clientes puede tener la imagen de tu empresa. Actualiza para personalizar los próximos envíos.',
    },
    integration: {
      title: 'Solicita una actualización para usar la API de Clicksign',
      titleAdvanced: 'Mejora tu plan Avanzado agregando API',
      text: 'Integra con la API y empieza a generar y enviar tus documentos automáticamente.',
      link: 'Más información en Documentación de API',
      buttonAdvanced: 'Agregar función',
    },
    batchDownload: {
      title: 'Descargar documentos de manera masiva',
      firstText: 'Descargar varios documentos de una vez de manera simple y rápida.',
      lastText: 'Disponible en los planes Start, Plus y Avanzado.',
    },
    customSender: {
      title: 'Remitente personalizado',
      firstText:
        'Elige un remitente para los correos electrónicos enviados por Clicksign, por ejemplo: registro@tuempresa.com',
      lastText: 'Disponible desde el Plan Empresarial. {0}',
      more: 'Más información.',
    },
    additionalAuths: {
      title: 'Haz una actualización para desbloquear funciones',
      titleMobile: 'Haz una actualización para desbloquear',
      firstText:
        'Aumenta la seguridad de tu proceso de firma agregando nuevas capas de validación para quienes firman.',
      secondText:
        'Haz una actualización de plan para usar Selfie con documento, Selfie dinámica y Biometría facial.',
    },
    accountAudit: {
      title: 'Haz una actualización para usar la auditoría de cuentas',
      firstText: `Ingresa al informe de auditoría de la cuenta para monitorear las actividades de los usuarios en la plataforma. Más seguridad y transparencia al utilizar Clicksign.`,
    },
    security: {
      title: 'Haz una actualización para usar funciones de seguridad',
      firstText:
        'Escoge el tiempo de sesión, autoriza acceso solo a IPs específicos y habilita el inicio de sesión con las credenciales del entorno de tu empresa (Single Sign On).',
    },
  },
  price: {
    title: 'Planes',
    planTitle: 'Plan {planName}',
    perfectPlan: 'Aquí tienes un plan perfecto para ti',
    categoryText: 'Planes anuales y mensuales desde',
    chooseSubtitle:
      'Planes sin compromiso, hechos a la medida para tu negocio. ¡Elige cuántos documentos quieres enviar y contrata!',
    chooseEventual: {
      title: '¿Aún no envías documentos todos los meses?',
      info: 'Elige el plan eventual y paga R$ {price} por documento finalizado.',
      action: 'Contratar plan Eventual',
    },
    value: {
      currency: 'R$',
      free: 'Gratis',
      period: 'mes',
      documents: 'documento | documentos',
      periodOld: 'Mes',
      bespokePerformance: 'Rendimiento personalizado',
    },
    automation: 'Empieza a generar tus documentos de forma automatizada y simple',
    noValue: 'Paga solo cuando lo utilices',
    seeMore: 'Ver más',
    seeMinor: 'Ver menos',
    action: {
      subscribe: 'Contratar',
      currentPlan: 'Plan actual',
      talkToSales: 'Hablar con ventas',
      choicePlan: 'Elegir',
      choiceCustomPlan: 'Habla con nuestro equipo comercial',
      talkToConsultant: 'Habla con un consultor',
    },
    calculator: {
      integration: {
        title: 'Simula el valor de tu factura',
        description: 'Cuanto más usas, menor es el precio por documento',
        inputLabel: 'Documentos por mes',
        pricingLabel: 'Valor de tu factura',
        pricingTooltip: 'SMS, WhatsApp y Widget tienen costos adicionales',
        customPlanSuggestionLabel: '¡Conoce el plan personalizado!',
        customPlanSuggestionDescription:
          'Lo ideal para ti es un plan personalizado. {0} Habla con nuestro equipo de ventas.',
        pricingDetailsTitle: 'Conoce el cálculo',
        resumedPricingRangeLabel: 'Rango de documentos',
        pricingRangeLabel: 'Rango de documentos (40, 100 o 200)',
        pricingBaseValueLabel: 'Precio del rango',
        pricingExceededDocumentsLabel: 'Documentos excedentes',
        pricingExceededDocumentsValueLabel: 'Precio del documento excedente',
        pricingTotalValue: 'Valor de la factura',
      },
    },
  },
  form: {
    automation: {
      challenger: {
        title: '¿Cuáles son los desafíos de tu proceso de firma actual?',
      },
      documentSystem: {
        title: '¿Tus documentos son generados por algún sistema? (CRM, ERP, Sistema propio, etc.)',
      },
      documentModel: {
        title: '¿Tus documentos siguen un plantilla estándar?',
      },
      functionality: {
        title: '¿Qué funcionalidad te gustaría conocer mejor?',
      },
      aditional: 'Deja un comentario, duda o necesidad adicional',
    },
    resume: {
      title: 'Resumen',
      monthDocuments:
        'R$8,50/documento | {quantity} documento mensual | {quantity} documentos mensuales',
      usersPerAccount: '1 Usuario en la cuenta | {quantity} Usuarios en la cuenta',
      integration: 'Facturación según el uso',
      whatsapp: 'Firmas más rápidas utilizando WhatsApp',
      price: 'R$ {price}/mes',
      // TOGGLE_SMB_PRICING_TEST_ENABLED
      // TODO: Remove priceWithoutCurrency and perMonth
      priceWithoutCurrency: '{price}/mes',
      perMonth: '/mes',
      fromValue: 'A partir de',
      button: 'Cambiar plan',
      documentBonus: 'Más {documentsAmount} documentos mensuales durante 3 meses',
      // TOGGLE_SMB_PRICING_TEST_ENABLED
      // TODO: Remove discountBadge
      discountBadge: 'Descuento aplicado',
    },
    title: 'Datos',
    subtitle: {
      info: 'Datos del cliente',
      contact: 'Contacto',
      address: 'Dirección de facturación',
    },
    field: {
      companyName: 'Nombre de la empresa',
      tradingName: 'Razón social',
      personName: 'Nombre completo',
      cnpj: 'CNPJ',
      cpf: 'CPF,',
      zipCode: 'CEP',
      dontKnowZipCode: '¿No conoces el CEP?',
      // TOGGLE_BRASIL_API_ZIPCODE_ENABLED
      // TODO: Remove `zipCodeInfo` locale below
      zipCodeInfo: 'Asegúrate de que el CEP sea correcto',
      address: {
        label: 'Dirección',
        placeholder: 'Calle, avenida',
      },
      number: {
        label: 'Número',
        placeholder: 'Número o s/n',
      },
      complement: {
        label: 'Complemento',
        labelOld: 'Complemento (opcional)',
        placeholder: 'Edificio, piso, departamento, etc.',
      },
      district: 'Colonia',
      city: 'Ciudad',
      uf: 'Estado',
      phoneNumber: 'Teléfono',
      segment: 'Segmento de actividad',
      legalPerson: 'Persona Jurídica',
      naturalPerson: 'Persona Física',
      paymentEmail: {
        label: 'Correo de facturación',
        help: 'Las facturas se enviarán a este correo.',
      },
    },
    nextStep: 'Confirmar',
  },
  checkout: {
    title: 'Pago',
    stepTitle: 'Pago',
    creditCardForm: {
      title: 'Datos de la tarjeta de crédito',
      visaAlt: 'Tarjeta Visa',
      mastercardAlt: 'Tarjeta Mastercard',
      eloAlt: 'Tarjeta Elo',
      americanExpressAlt: 'Tarjeta American Express',
      dinersClubAlt: 'Tarjeta Diners',
      hipercardAlt: 'Tarjeta HiperCard',
      titleTooltip: 'Aceptamos las principales marcas de tarjetas',
      description: 'La factura mensual  se cargará en esta tarjeta.',
      cardNumber: 'Número de la tarjeta',
      cardNumberPlaceHolder: '0000 0000 0000 0000',
      cardName: 'Nombre impreso en la tarjeta',
      expireDate: 'Fecha de vencimiento',
      expireDatePlaceholder: 'MM/AAAA',
      cvv: 'CVV',
      cvvPlaceholder: '000',
      submit: 'Confirmar',
      success: 'Tarjeta agregada con éxito',
    },
    error: {
      creditCardInvalid:
        'Revisa la información y el límite de la tarjeta seleccionada o intenta usar una tarjeta diferente.',
    },
    bankSlipForm: {
      description: 'La factura será enviada por correo después de la confirmación.',
    },
    subscriptionLoadingModal: {
      title: 'Procesando el pago',
    },
    creditCardErrorModal: {
      title: 'Pago rechazado o no procesado',
      description:
        'Revisa la información y el límite de la tarjeta elegida o intenta usar una tarjeta diferente.',
    },
  },
  resume: {
    title: 'Confirmación',
    subtitleBankSlip: '¡Tu plan está temporalmente activo!',
    subtitleCreditCard: '¡Éxito, tu plan está activo!',
    eventualSubtitle: '¡Tu plan está activo!',
    customSubtitle: 'Solicitud realizada con éxito',
    descriptionBankSlip:
      'Realiza el pago para garantizar el acceso. Para pagar la factura, haz clic en el botón a continuación o ingresa a la factura enviada por correo.',
    descriptionCreditCard: 'Disfruta de los nuevos recursos disponibles para ti.',
    eventualSubtitleBankSlip: '¡Tu plan está activo!',
    eventualDescriptionBankSlip: 'Recibirás la factura por correo si realizas algún consumo.',
    eventualSubtitleCreditCard: '¡Éxito, tu plan está activo!',
    eventualDescriptionCreditCard:
      'El cargo se aplicará a la tarjeta de crédito indicada cuando se realice un consumo.',
    eventualCreditCardHelpLink:
      'Si la factura del mes actual es menor a R$ 30,00, el cobro se realizará el mes siguiente.',
    customDescription:
      'Nuestro equipo comercial se pondrá en contacto contigo en breve para completar tu contratación.',
    help: {
      text: '¿Dudas? Envíanos un correo a:',
      email: 'ayuda@clicksign.com',
    },
    goToPayment: 'Ir al pago',
    goToHome: 'Ir al inicio',
    action: 'Confirma la elección tu plan',
  },
  success: {
    title: 'Plan {plan} activado',
    description: 'Hemos enviado un correo a {0} con instrucciones para completar el pago.',
    action: 'Ir a mi cuenta',
  },
  blockedByAdminModal: {
    title: '{blockedFeatureTitle} no disponible para tu cuenta',
    description: 'Contacta al equipo de soporte para habilitar esta funcionalidad.',
    action: 'Entendido',
    blockedFeatureTitle: {
      acceptanceTermByWhatsapp: 'WhatsApp',
    },
  },
};

export default subscription;
