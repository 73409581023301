// import { getViewProps } from '@base/utils/getViewDataset';

// TODO: EUR-208 (https://clicksign.atlassian.net/browse/EUR-208)
// Remove comments to unify routes /signer/profile and /operator
const routes = [
  // {
  //   path: '/profile',
  //   component: () => import('@users/views/UserProfileView.vue'),
  //   meta: {
  //     layout: 'BaseDefaultLayout',
  //     refresh: () => true,
  //   },
  //   props: () => ({
  //     ...getViewProps(),
  //   }),
  // },
];

export default routes;
