import liveness from './liveness';
import facialBiometrics from './facial-biometrics';
import voiceBiometrics from './voice-biometrics';

const steps = {
  liveness,
  facialBiometrics,
  voiceBiometrics,
};

export default steps;
