<script setup>
import { computed } from 'vue';
import { useVModel } from '@base/hooks';
import { useStore } from '@/store';
import { useFormFlowListFilters } from '@flow/composables';
import {
  FormFlowListFilters,
  FormFlowEmptyList,
  FormFlowEmptySearch,
  FormFlowCardItem,
} from '@flow/components/form-flow';
import { FLOW_CONTEXT_NAMES_CONSTANTS, FORM_FLOW_ACTIONS_CONSTANTS } from '@flow/constants';

const props = defineProps({
  context: {
    type: String,
    default: FLOW_CONTEXT_NAMES_CONSTANTS.flowList,
  },
  currentView: {
    type: String,
    default: '',
  },
  viewOptions: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['handle-new', 'click-option', 'open-modal', 'update:currentView']);

const currentView = useVModel(props, 'currentView', emit);
const store = useStore();
const { hasFilters } = useFormFlowListFilters();

const loading = computed(() => store.getters['formFlow/getLoading']);
const formFlows = computed(() => store.getters['formFlow/getFormFlows']);
const totalItems = computed(() => store.getters['formFlow/getTotalItems']);
const pagination = computed(() => store.getters['formFlow/getPagination']);
const account = computed(() => store.getters['info/getAccount']);
const disabledActions = computed(() =>
  props.context === FLOW_CONTEXT_NAMES_CONSTANTS.flowList
    ? [FORM_FLOW_ACTIONS_CONSTANTS.removeFromFolder]
    : []
);
</script>

<template>
  <XCard data-testid="formFlowCardListContainer">
    <div
      v-if="totalItems !== 0 || hasFilters"
      :class="$style.tableActions"
      data-testid="tableActions"
    >
      <FormFlowListFilters :total-items="totalItems" />

      <XSelect
        v-model="currentView"
        :items="viewOptions"
      />
    </div>
    <template v-if="totalItems === 0 && !loading">
      <FormFlowEmptyList
        v-if="!hasFilters"
        icon="code-branch"
        :title="$t('flowModule.emptyList.title')"
        :description="$t('flowModule.emptyList.description')"
        :action-text="$t('flowModule.new')"
        data-testid="formFlowEmptyList"
        @handle-new="$emit('handle-new')"
      />
      <FormFlowEmptySearch
        v-else
        :button-text="$t('flowModule.emptySearch.button')"
      />
    </template>
    <template v-else>
      <div
        :class="$style.list"
        data-testid="formFlowCardList"
      >
        <template v-if="loading">
          <XCard
            v-for="nCard in 2"
            :key="nCard"
            :class="$style.skeletonWrapper"
            data-testid="formFlowCardListSkeleton"
            outlined
          >
            <XSkeletonLoader height="32px" />
            <XDivider />
            <div
              v-for="nRow in 2"
              :key="nRow"
              :class="$style.row"
            >
              <XSkeletonLoader
                height="20px"
                width="40%"
              />
              <XSkeletonLoader
                height="20px"
                width="70%"
              />
            </div>
            <div :class="$style.footer">
              <XSkeletonLoader
                height="32px"
                width="100px"
              />
            </div>
          </XCard>
        </template>
        <template v-else>
          <FormFlowCardItem
            v-for="(form, index) in formFlows"
            :key="index"
            :item="form"
            :account="account"
            :disabled-actions="disabledActions"
            :context="context"
            @open-modal="(modalType, item) => $emit('open-modal', modalType, item)"
            @click-option="(event) => $emit('click-option', event)"
          />
        </template>
      </div>
      <XPagination
        v-if="pagination?.pageCount > 1"
        :class="$style.containerPagination"
        align="center"
        :active-page="pagination?.page"
        :total-pages="pagination?.pageCount"
        :next-text="$t('general.action.next')"
        :prev-text="$t('general.action.previous')"
        data-testid="paginationContainer"
      />
    </template>
  </XCard>
</template>

<style lang="scss" module>
.containerPagination {
  margin-top: var(--space-regular);
}

.tableActions {
  display: flex;
  width: 100%;
  margin-bottom: var(--space-medium);
  gap: var(--space-medium);
}

.list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: var(--space-medium);

  .skeletonWrapper {
    border-color: var(--color-neutral-400);

    .row {
      display: flex;
      flex-direction: column;
      margin-bottom: var(--space-regular);
      gap: var(--space-small-xxx);
    }

    .footer {
      margin-top: var(--space-large);
    }
  }
}
</style>
