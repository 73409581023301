import { axios } from '@/plugins';

const headers = { 'Content-Type': 'multipart/form-data' };

const verifyVoiceBiometrics = async ({ getters }, formData) => {
  const url = getters.getLinks.voiceBiometricsVerify;
  const response = await axios.post(url, formData, { headers });

  return response?.data?.status;
};

const enrollVoiceBiometrics = async ({ getters }, formData) => {
  const url = getters.getLinks.voiceBiometricsEnroll;
  const response = await axios.post(url, formData, { headers });

  return response?.data?.status;
};

export default {
  verifyVoiceBiometrics,
  enrollVoiceBiometrics,
};
