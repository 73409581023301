const template = {
  setup: {
    title: 'Modelo de documento',
    info: 'Defina seu modelo a partir de um arquivo Word (.docx) com variáveis',
    nameLabel: 'Nome do modelo',
    uploadLabel: 'Selecione um arquivo Word (.docx) com as variáveis',
    documentExample: 'Baixar documento de exemplo com variáveis',
    colorLabel: 'Escolha uma cor para você identificar este modelo',
    save: 'Salvar',
    editTemplate: 'Editar modelo',
    fatalError: {
      title: 'Erro ao carregar os dados do modelo',
      message: 'Tente novamente. Se o problema persistir envie um e-mail para ajuda@clicksign.com.',
    },
    notifications: {
      update: 'Modelo atualizado com sucesso.',
      create: 'Modelo criado com sucesso.',
    },
    create: {
      documentsTemplates: 'Modelo de documentos',
      createTemplate: 'Criar modelo',
    },
    edit: {
      documentsTemplates: 'Modelos de documentos',
      editTemplate: 'Editar modelo',
      successMessage: 'Alterações salvas com sucesso',
      errorMessage: 'Ocorreu um erro ao atualizar o modelo',
      loadingEditor: 'Seu documento está sendo carregado para edição. {0} Aguarde um momento...',
    },
    templateByDocuments: {
      title: 'Modelo inteligente (IA)',
      label: 'Selecione um documento em PDF',
      send: 'Gerar',
      alert:
        'Crie o seu modelo a partir de dois arquivos PDFs já existentes, para gerar uma automação',
    },
  },
};

export default template;
