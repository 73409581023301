const rubric = {
  error: 'An error occurred while performing the signature!',
  documentView: 'Please scroll through all pages',
  readTooltip: 'The mandatory scroll is pending',
  readConfirmation: 'Seen',
  readWidgetConfirmation: 'All pages have been seen',
  readError: 'Scrolling through all pages is mandatory',
  tooltip: 'The mandatory scroll is pending',
  modal: {
    clauses: {
      title: 'Clause Reading Confirmation',
      desc: 'To complete the signing, confirm your awareness of the following content:',
      itens: 'Clauses:',
      accept: 'I have read and agree to the clauses/items',
    },
    seals: {
      title: 'Initials in the corner of the page',
      desc: 'The pages below require a initials.',
      pages: 'Pages:',
      all: 'All pages',
      placeholder: 'bc',
      label: 'Enter your initials to define the signature (e.g., BC).',
    },
    agree: 'Confirm',
    title: 'Initials in the corner of the page',
    electronicRubric: 'Electronic signature',
    multipleDocuments:
      'To continue, confirm your initials on the pages of the following document:|To continue, confirm your initials on the pages of the following documents:',
    singleDocumentAllPages: 'To continue, confirm your initials on all pages of the document.',
    singleDocumentSomePages:
      'To continue, confirm your initials on the following page:|To continue, confirm your initials on the following pages:',
    pages: 'Page|Pages',
    allPages: 'All pages',
    insertInitials: 'Define your initials below (eg BC).',
    initialsPlaceholder: 'Bc',
    confirm: 'Sign and initial',
  },
};

export default rubric;
