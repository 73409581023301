const general = {
  loading: 'Carregando',
  saving: 'Salvando...',
  filters: 'Filtros',
  use: 'Usar',
  disabledTextInput: 'Caixa de texto desabilitada',
  disabled: 'Desabilitado',
  useTerms: 'Termos de uso',
  and: 'e',
  action: {
    select: 'Selecionar',
    selectDots: 'Selecione...',
    selectOption: 'Selecionar uma opção',
    selectDate: 'Selecione uma data...',
    cleanSelection: 'Limpar seleção',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    save: 'Salvar',
    saveChange: 'Salvar Alteração',
    send: 'Enviar',
    advance: 'Avançar',
    back: 'Voltar',
    next: 'Próximo',
    nextPage: 'Próxima',
    previous: 'Anterior',
    create: 'Criar',
    remove: 'Remover',
    move: 'Mover',
    add: 'Adicionar',
    delete: 'Excluir',
    ignore: 'Ignorar',
    open: 'Abrir',
    close: 'Fechar',
    refresh: 'Atualizar',
    edit: 'Editar',
    download: 'Baixar',
    duplicate: 'Duplicar',
    disable: 'Desativar',
    preview: 'Pré-visualizar',
    ok: 'Ok',
    seeMore: 'Ver mais',
    seeAll: 'Ver tudo',
    seeMoreFromGroup: 'Ver mais signatários do grupo',
    seeMoreSigners: 'Ver mais signatários',
    seeMoreGroups: 'Ver mais grupos',
    continue: 'Continuar',
    fix: 'Corrigir',
    yes: 'Sim',
    no: 'Não',
    upgrade: 'Upgrade',
    help: 'Ajuda',
    tryAgain: 'Tentar novamente',
    conclude: 'Concluir',
    out: 'Sair',
    search: 'Busca',
    finish: 'Finalizar',
    saveAndBack: 'Salvar e voltar',
    discardChanges: 'Descartar alterações',
    resend: 'Reenviar',
    rename: 'Renomear',
    moveToFolder: 'Mover para pasta',
    error: {
      download: 'Erro ao baixar o arquivo',
    },
  },
  person: {
    legalPerson: 'Pessoa Jurídica',
    naturalPerson: 'Pessoa Física',
  },
  feature: {
    attention: 'Atenção',
    beta: 'Beta',
    new: 'Novo',
    more_info: 'Saiba mais.',
    testBetaFeatures: 'Teste novos recursos antes deles serem lançados oficialmente.',
    moreInfo: 'Saber mais',
  },
  date: {
    days: '{days} dia | {days} dias',
    today: 'Hoje',
    yesterday: 'Ontem',
  },
  form: {
    requiredFields: 'Campos obrigatórios',
  },
  badge: {
    new: 'Novo!',
  },
  secureEnvironment: 'Ambiente seguro Clicksign',
  slowLoading: 'O carregamento desta página está demorando mais do que o esperado',
  help: {
    email: 'ajuda@clicksign.com',
    helpCenter: 'Central de ajuda',
    support: 'Fale com o suporte',
  },
  placeholders: {
    phoneNumber: '(__) ____-____',
    birthday: 'dd/mm/aaaa',
    cpf: '___.___.___-__',
  },
  statusAlert: {
    link: 'Ver detalhes',
  },
};

export default general;
