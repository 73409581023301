import { computed } from 'vue';

export const useVModel = (props, key, emit) => {
  const eventName = `update:${key}`;

  const data = computed({
    get: () => props[key],
    set: (newValue) => emit(eventName, newValue),
  });

  return data;
};
