import { axios } from '@/plugins';

const fetchFlowFolders = async ({ commit }, { self }) => {
  try {
    const { data } = await axios.get(self, {
      params: {
        perPage: 10,
      },
    });

    commit('SET_LINKS', data.links);
    commit('SET_PAGINATION', data.pagination);
    commit('SET_FOLDERS', data.flowFolders);
    commit('SET_TOTAL_ITEMS', data.totalItems);
  } catch (err) {
    throw err;
  }
};

const createFlowFolder = async ({ getters }, { name }) => {
  try {
    const url = getters.getLinks.createFolder;

    return await axios.post(url, { name });
  } catch (err) {
    throw err;
  }
};

const deleteFlowFolder = async ({ getters }) => {
  try {
    await axios.delete(getters.getSelectedFolder.links.delete);
  } catch (err) {
    throw err;
  }
};

const renameFlowFolder = async ({ getters }, { name }) => {
  try {
    await axios.put(getters.getSelectedFolder.links.self, { name });
  } catch (err) {
    throw err;
  }
};

const fetchFlowFolder = async ({ commit }, { link }) => {
  const {
    data: { flowFolder },
  } = await axios.get(link);

  commit('SET_FOLDER', flowFolder);
  commit('SET_SELECTED_FOLDER', flowFolder);
};

const fetchUnassignedFlows = async ({ commit, getters }, { flowName }) => {
  const url = getters.getFolder.links.filterForms;
  const nextPage = (getters.getUnassignedFlowsPagination.page ?? 0) + 1;
  const params = {
    page: nextPage,
    flowName,
  };
  const response = await axios.get(url, { params });
  const { flows, pagination } = response.data.filters;

  commit('SET_UNASSIGNED_FLOWS', [...getters.getUnassignedFlows, ...flows]);
  commit('SET_UNASSIGNED_FLOWS_PAGINATION', pagination);
};

const resetUnassignedFlows = ({ commit }) => {
  commit('SET_UNASSIGNED_FLOWS', []);
  commit('SET_UNASSIGNED_FLOWS_PAGINATION', {});
};

const addFlowsToFolder = async ({ getters }, { flowFormIds }) => {
  const url = getters.getSelectedFolder?.links?.addForms;

  await axios.patch(url, { flowFormIds });
};

const setSelectedFolder = ({ commit }, folder) => {
  commit('SET_SELECTED_FOLDER', folder);
};

const setSelectedFlows = ({ commit }, flows) => {
  commit('SET_SELECTED_FLOWS', flows);
};

const setLinks = ({ commit }, links) => {
  commit('SET_LINKS', links);
};

export default {
  fetchFlowFolders,
  createFlowFolder,
  deleteFlowFolder,
  renameFlowFolder,
  fetchFlowFolder,
  addFlowsToFolder,
  fetchUnassignedFlows,
  resetUnassignedFlows,
  setSelectedFolder,
  setSelectedFlows,
  setLinks,
};
