const downloads = {
  title: 'Documentos descargados',
  description: 'Haz seguimiento de tus solicitudes para descargar aquí',
  backupInfo: {
    description:
      'Los administradores pueden hacer copias de seguridad de todos los documentos de la cuenta.',
    tip: 'Para hacer más de 3 copias de seguridad, espera hasta que alguna de ellas expire.',
    tipLimit: 'Límite de copias de seguridad alcanzado. Espera hasta que alguna expire.',
    backupLimit: 'Copias de seguridad restantes',
    backup: 'Hacer copia de seguridad',
  },
  table: {
    date: 'Fecha',
    email: 'Correo del usuario',
    type: 'Tipo de archivo',
    status: 'Estatus',
    requester: 'Solicitante',
    item: 'Elemento',
    statuses: {
      ready: 'Descargar',
      error: 'Error',
      expired: 'Expirado',
      downloaded: 'Descargado en',
      processing: 'Procesando',
    },
    tips: {
      ready: 'El enlace para descargar estará disponible hasta la fecha límite',
      error: 'Se ha producido un error, por favor intenta descargar nuevamente',
      expired: 'Por la seguridad de los documentos, el enlace para descargar ha expirado',
      processing: 'Cuando los archivos estén listos, podrás descargarlos aquí',
    },
    until: 'Hasta {0}',
    at: 'En {0}',
    empty: {
      title: 'Nada hay descargas realizadas por ahora',
      description: '¡Aquí podrás seguir el historial de descargas!',
    },
  },
  modalDetails: {
    title: 'Detalles',
    description: 'Revisa los archivos comprimidos para descargar',
    nodes: 'Documento y carpeta',
    backup: 'Copia de seguridad de documentos',
  },
  modalListBackup: {
    title: 'Descargar archivos',
    description: 'Clique para descargar los archivos comprimidos',
  },
};

export default downloads;
