<script setup>
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useStore } from '@/store';
import { useI18n } from '@/locales';
import { FEATURES } from '@base/constants';
import FlowDisabledContainer from '@flow/containers/disabled/FlowDisabledContainer.vue';
import {
  useFunctionalities,
  useGA,
  useModal,
  useRoute,
  useRouter,
  useToast,
  useToggle,
} from '@base/hooks';
import {
  PendingAnswersHeader,
  PendingAnswersDetailsModal,
  PendingAnswersDisapproveModal,
  PendingAnswersWhatsappReminderModal,
  PendingAnswersFilters,
  PendingAnswersBulkActionReminderModal,
  PendingAnswersBulkActionDisapproveModal,
} from '@flow/components/pending-answers';
import { camelizeKeys } from '@base/utils';

const router = useRouter();
const route = useRoute();
const store = useStore();
const { t, tc } = useI18n();
const toast = useToast();
const { isEnabled } = useFunctionalities();
const { isModalActive, openModal, closeModal } = useModal();
// TOGGLE_SEARCH_PENDING_ANSWERS_ENABLED
// TODO: Remove toggleSearchPendingAnswersEnabled
const { isEnabled: toggleSearchPendingAnswersEnabled } = useToggle(
  'toggle_search_pending_answers_enabled'
);
// TOGGLE_BULK_ACTIONS_ON_PENDING_ANSWERS_ENABLED
// TODO: Remove toggleBulkActionsOnPendingAnswersEnabled
const { isEnabled: toggleBulkActionsOnPendingAnswersEnabled } = useToggle(
  'toggle_bulk_actions_on_pending_answers_enabled'
);
const loading = ref(false);
const orderBy = ref({});

const HEADERS = [
  {
    label: t('pendingAnswers.table.secondRecipient'),
    value: 'secondRecipient',
    // TOGGLE_SEARCH_PENDING_ANSWERS_ENABLED
    // TODO: Remove toggleSearchPendingAnswersEnabled.value set sortable as true
    sortable: toggleSearchPendingAnswersEnabled.value,
  },
  {
    label: t('pendingAnswers.table.name'),
    value: 'name',
    // TOGGLE_SEARCH_PENDING_ANSWERS_ENABLED
    // TODO: Remove toggleSearchPendingAnswersEnabled.value set sortable as true
    sortable: toggleSearchPendingAnswersEnabled.value,
  },
  {
    label: t('pendingAnswers.table.createdAt'),
    value: 'createdAt',
    // TOGGLE_SEARCH_PENDING_ANSWERS_ENABLED
    // TODO: Remove toggleSearchPendingAnswersEnabled.value set sortable as true
    sortable: toggleSearchPendingAnswersEnabled.value,
  },
  {
    label: t('pendingAnswers.table.action'),
    value: 'action',
    colspan: 2,
  },
];
const ORDER_FIELDS_MAPPING = {
  secondRecipient: 'second_recipient',
  name: 'flow_forms.name',
  createdAt: 'created_at',
};

/**
 * ISSUE: https://github.com/clicksign/excalibur/issues/788
 * TODO: Deve preencher selectedRows com linhas da tabela que estão selecionadas
 */
const tableEl = ref();
const selectedRows = computed(() => {
  return tableEl.value?._setupProxy?.selectedRows ?? [];
});
const showBulkActions = computed(() => selectedRows.value.length > 0);

const pendingAnswers = computed(() => store.getters['pendingAnswers/getPendingAnswers']);
const selectedProcess = computed(() => store.getters['pendingAnswers/getSelectedProcess']);
const selectedProcessesList = computed(
  () => store.getters['pendingAnswers/getSelectedProcessesList']
);
const pagination = computed(() => store.getters['pendingAnswers/getPagination']);
const orderByParam = computed(() => route.query.order_by || route.query.orderBy);
const orderDirectionParam = computed(
  () => route.query.order_direction || route.query.orderDirection
);

const getOrderFieldByQueryParam = (queryParam) =>
  Object.keys(ORDER_FIELDS_MAPPING).find((key) => ORDER_FIELDS_MAPPING[key] === queryParam);

const fetchPendingAnswers = async () => {
  loading.value = true;
  await store.dispatch('pendingAnswers/fetchPendingAnswers', {
    url: window.location.href,
  });
  await store.dispatch('pendingAnswers/fetchFlowsFilter');
  loading.value = false;
};

/**
 * ISSUE: https://github.com/clicksign/excalibur/issues/788
 * TODO: Deve desmarcar todas a linhas da tabela
 */
const resetSelectedRows = () => {
  if (tableEl.value?._setupProxy?.selectedRows) {
    tableEl.value._setupProxy.selectedRows = [];
  }
};

const handleShowDetails = (item) => {
  store.commit('pendingAnswers/SET_SELECTED_PROCESS', item);
  openModal('PendingAnswersDetailsModal');
};

const handleShowDisapprove = (item) => {
  // TOGGLE_BULK_ACTIONS_ON_PENDING_ANSWERS_ENABLED
  // TODO: Remove if/else condition and keep if content
  if (toggleBulkActionsOnPendingAnswersEnabled.value) {
    store.commit('pendingAnswers/SET_SELECTED_PROCESSES_LIST', [item]);
  } else {
    store.commit('pendingAnswers/SET_SELECTED_PROCESS', item);
  }

  openModal('PendingAnswersDisapproveModal');
};

const handleDisapproveAnswer = async () => {
  try {
    // TOGGLE_BULK_ACTIONS_ON_PENDING_ANSWERS_ENABLED
    // TODO: Remove if/else condition and keep if content
    if (toggleBulkActionsOnPendingAnswersEnabled.value) {
      const payload = {
        ids: selectedProcessesList.value.map((process) => process.id),
      };

      await store.dispatch('pendingAnswers/disapproveInBatch', payload);

      toast.success(tc('pendingAnswers.actionDisapprove', selectedProcessesList.value.length));
    } else {
      await store.dispatch(
        'pendingAnswers/fetchActionAnswer',
        selectedProcess.value.links.disapprove
      );

      toast.success(t('pendingAnswers.actionDisapproveOld'));
    }

    fetchPendingAnswers();
    useGA('event', 'archive-pendencie-click', 'automation-forms-pendencies-app');
    closeModal();
    resetSelectedRows();
  } catch (err) {
    toast.error(t('pendingAnswers.actionError'));
  }
};

const handleWhatsappReminder = async () => {
  try {
    // TOGGLE_BULK_ACTIONS_ON_PENDING_ANSWERS_ENABLED
    // TODO: Remove if/else condition and keep if content
    if (toggleBulkActionsOnPendingAnswersEnabled.value) {
      const payload = {
        ids: selectedProcessesList.value.map((process) => process.id),
      };

      await store.dispatch('pendingAnswers/sendReminderInBatch', payload);

      toast.success(tc('pendingAnswers.actionReminder', selectedProcessesList.value.length));
    } else {
      await store.dispatch(
        'pendingAnswers/fetchActionAnswer',
        selectedProcess.value.links.reminder
      );

      toast.success(t('pendingAnswers.actionReminderOld'));
    }

    useGA('event', 'send-pendencie-link-click', 'automation-forms-pendencies-app');
    closeModal();
    resetSelectedRows();
  } catch (err) {
    toast.error(t('pendingAnswers.actionError'));
  }
};

const handleShowWhatsappReminder = (item) => {
  // TOGGLE_BULK_ACTIONS_ON_PENDING_ANSWERS_ENABLED
  // TODO: Remove if/else condition and keep if content
  if (toggleBulkActionsOnPendingAnswersEnabled.value) {
    store.commit('pendingAnswers/SET_SELECTED_PROCESSES_LIST', [item]);
  } else {
    store.commit('pendingAnswers/SET_SELECTED_PROCESS', item);
  }

  if (item.notificationByWhatsapp) {
    openModal('PendingAnswersWhatsappReminderModal');
  } else {
    handleWhatsappReminder();
  }
};

const handleShowBulkActionReminderModal = () => {
  store.commit('pendingAnswers/SET_SELECTED_PROCESSES_LIST', selectedRows.value);
  openModal('PendingAnswersBulkActionReminderModal');
};

const handleShowBulkActionDisapproveModal = () => {
  store.commit('pendingAnswers/SET_SELECTED_PROCESSES_LIST', selectedRows.value);
  openModal('PendingAnswersBulkActionDisapproveModal');
};

const handleSort = async (direction, { value: sortBy }) => {
  let orderDirection;

  if (ORDER_FIELDS_MAPPING[sortBy] === orderByParam.value) {
    orderDirection = route.query.orderDirection === 'asc' ? 'desc' : 'asc';
  }

  await router.replace({
    query: {
      ...camelizeKeys(route.query),
      orderBy: ORDER_FIELDS_MAPPING[sortBy],
      orderDirection: orderDirection || direction,
    },
  });
};

onMounted(() => {
  fetchPendingAnswers();
});

watchEffect(() => {
  orderBy.value = {
    sortBy: getOrderFieldByQueryParam(orderByParam.value),
    direction: orderDirectionParam.value,
  };
});
</script>

<template>
  <div>
    <div
      v-if="isEnabled(FEATURES.FORM_FLOW)"
      :class="$style.container"
      data-testid="pendingAnswersView"
    >
      <!-- TOGGLE_SEARCH_PENDING_ANSWERS_ENABLED -->
      <!-- TODO: Remove loading property -->
      <PendingAnswersHeader :loading="loading" />
      <XCard elevation="low">
        <XCard
          v-if="showBulkActions"
          :class="$style.bulkActions"
          padding="var(--space-small-x) var(--space-regular)"
        >
          <div :class="$style.cancelWrapper">
            <XButton
              model="plain"
              @click="resetSelectedRows"
            >
              <XIcon icon="close" />
            </XButton>
            <span :class="$style.totalSelected">
              {{
                $tc('pendingAnswers.bulkActions.totalSelected', selectedRows.length, {
                  count: selectedRows.length,
                })
              }}
            </span>
          </div>
          <XButton
            model="plain"
            @click="handleShowBulkActionReminderModal"
          >
            <XIcon
              icon="paper-plane"
              left
            />
            {{ $t('pendingAnswers.bulkActions.reminder') }}
          </XButton>

          <XButton
            model="plain"
            @click="handleShowBulkActionDisapproveModal"
          >
            <XIcon
              icon="trash"
              left
            />
            {{ $t('pendingAnswers.bulkActions.delete') }}
          </XButton>
        </XCard>

        <!-- TOGGLE_SEARCH_PENDING_ANSWERS_ENABLED -->
        <!-- TODO: Remove v-if condition -->
        <PendingAnswersFilters
          v-if="toggleSearchPendingAnswersEnabled"
          :loading="loading"
          data-testid="pendingAnswersFilters"
        />

        <template v-if="pendingAnswers.length > 0 || loading">
          <!-- ISSUE: https://github.com/clicksign/excalibur/issues/866 -->
          <!-- TODO: Adicionar a prop :sort e passar corretamente o orderBy -->
          <!-- TOGGLE_SEARCH_PENDING_ANSWERS_ENABLED -->
          <!-- TODO: Set :checkable as true -->
          <XTable
            ref="tableEl"
            data-testid="pendingAnswersViewTable"
            :class="$style.table"
            :headers="HEADERS"
            :items="pendingAnswers"
            :loading="loading"
            :checkable="toggleBulkActionsOnPendingAnswersEnabled"
            @sort="handleSort"
          >
            <template #items-col-secondRecipient="{ item }">
              <XLink
                :class="$style.link"
                @click="handleShowDetails(item)"
              >
                {{ item.secondRecipient }}
              </XLink>
            </template>
            <template #items-col-name="{ item }">
              <XLink
                :class="$style.link"
                @click="handleShowDetails(item)"
              >
                {{ item.name }}
              </XLink>
            </template>
            <template #items-col-createdAt="{ item }">
              <XLink
                :class="$style.link"
                @click="handleShowDetails(item)"
              >
                {{ item.createdAt }}
              </XLink>
            </template>
            <template #items-col-action="{ item }">
              <div :class="$style.actions">
                <XTooltip placement="top">
                  <template #activator>
                    <XButton
                      border-radius="circle"
                      size="sm"
                      design="text"
                      data-testid="reminderButton"
                      @click="handleShowWhatsappReminder(item)"
                    >
                      <XIcon icon="paper-plane" />
                    </XButton>
                  </template>
                  {{ $t('pendingAnswers.table.reminder') }}
                </XTooltip>
                <XTooltip placement="top">
                  <template #activator>
                    <XButton
                      border-radius="circle"
                      size="sm"
                      design="text"
                      data-testid="disapproveButton"
                      @click="handleShowDisapprove(item)"
                    >
                      <XIcon icon="trash" />
                    </XButton>
                  </template>
                  {{ $t('pendingAnswers.table.delete') }}
                </XTooltip>
              </div>
            </template>
          </XTable>
          <XPagination
            v-if="pagination.pageCount > 1"
            align="center"
            :next-text="$t('general.action.next')"
            :prev-text="$t('general.action.previous')"
            :total-pages="pagination.pageCount"
            data-testid="paginationContainer"
          />
        </template>
        <i18n
          v-else
          :class="$style.emptyText"
          tag="p"
          path="pendingAnswers.emptyText"
        >
          <br />
        </i18n>
      </XCard>
    </div>
    <FlowDisabledContainer
      v-else
      type="default"
      data-testid="flowDisabledContainer"
    />
    <PendingAnswersDetailsModal
      :show-modal="isModalActive('PendingAnswersDetailsModal')"
      @close="closeModal"
    />
    <PendingAnswersDisapproveModal
      :show-modal="isModalActive('PendingAnswersDisapproveModal')"
      data-testid="disapproveModal"
      @close="closeModal"
      @confirm="handleDisapproveAnswer"
    />
    <PendingAnswersWhatsappReminderModal
      :show-modal="isModalActive('PendingAnswersWhatsappReminderModal')"
      @confirm="handleWhatsappReminder"
      @close="closeModal"
    />
    <PendingAnswersBulkActionReminderModal
      :show-modal="isModalActive('PendingAnswersBulkActionReminderModal')"
      @confirm="handleWhatsappReminder"
      @close="closeModal"
    />
    <PendingAnswersBulkActionDisapproveModal
      :show-modal="isModalActive('PendingAnswersBulkActionDisapproveModal')"
      @close="closeModal"
      @confirm="handleDisapproveAnswer"
    />
  </div>
</template>

<style lang="scss" module>
.container {
  padding: var(--space-large);

  .table {
    margin-bottom: var(--space-medium);

    .link {
      color: var(--color-neutral-1000);
    }

    .actions {
      display: flex;
      gap: var(--space-small-xx);
    }
  }

  .emptyText {
    margin-top: var(--space-regular);
    text-align: center;
    color: var(--color-neutral-700);
  }
}

.bulkActions {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: var(--space-regular);
  gap: var(--space-medium);
  background-color: var(--color-neutral-50);

  .cancelWrapper {
    display: flex;
    gap: var(--space-small-x);

    .totalSelected {
      font-size: var(--font-size-small);
    }
  }
}
</style>
