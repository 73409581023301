import { camelCase } from 'lodash';

export const customSnakeCase = (s) => _.chain(s).split(/(\d+)/).map(_.snakeCase).join('').value();

export const mapKeysDeep = (obj, fn) => {
  if (Array.isArray(obj)) return obj.map((v) => mapKeysDeep(v, fn));
  if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [fn(key)]: mapKeysDeep(obj[key], fn),
      }),
      {}
    );
  }
  return obj;
};

export const mapValuesDeep = (obj, fn) => {
  if (Array.isArray(obj)) return obj.map((v) => mapValuesDeep(v, fn));
  if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [key]: mapValuesDeep(obj[key], fn),
      }),
      {}
    );
  }
  return fn(obj);
};

export const camelizeKeys = (obj) => mapKeysDeep(obj, camelCase);

export const snakeCaseKeys = (obj) => mapKeysDeep(obj, customSnakeCase);

export * from './format';
export * from './sanitize-date-filters';
export * from './currency';
export * from './wait';
export * from './get-blob-from-uri';
export * from './get-cross-origin-worker-uri';
export * from './action-cable';
export * from './image';
export * from './sort-collection';
