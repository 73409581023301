import { axios } from '@/plugins';
import { mountFieldPayload } from '@field-creator/utils';

const sendFields = async ({ getters }, link) => {
  await axios.post(link, {
    flow: {
      formBuilderAttributes: {
        formFields1: mountFieldPayload(getters.getFormFields1),
        formFields2: mountFieldPayload(getters.getFormFields2),
      },
    },
  });
};

const setPreventCopy = ({ commit }, preventCopy) => {
  commit('SET_PREVENT_COPY', preventCopy);
};

export default {
  sendFields,
  setPreventCopy,
};
