const signature = {
  step: {
    index: {
      signerInfo: 'Confirmar seus dados',
      livenessConfirmation: 'Tirar uma foto sua (Selfie)',
      tokenConfirmation: 'Código de Confirmação',
    },
    token: {
      title: 'Código de Confirmação',
    },
    expiredLink: {
      title: 'Este link expirou',
      description: 'Verifique se existe outro link disponível no e-mail enviado.',
      help: 'Em caso de dúvidas contate seu Gerente de Negócios ou Canal de Atendimento.',
    },
  },
  success: {
    description:
      'Você receberá um e-mail com o contrato assinado após processarmos sua solicitação. | Você receberá um e-mail com os contratos assinados após processarmos sua solicitação.',
    cta: {
      voiceBiometrics: {
        title: 'Fique ainda mais protegido!',
        description: 'Grave a sua voz e a VIVO irá usá-la como senha.',
        action: 'Começar',
      },
    },
  },
};

export default signature;
