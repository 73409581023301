<script setup>
import { useI18n } from '@/locales';
import { camelCase } from 'lodash';
import { computed } from 'vue';

const props = defineProps({
  folder: {
    type: Object,
    default: () => {},
  },
});
const emit = defineEmits(['rename', 'delete']);

const { t } = useI18n();

const optionsDropdown = computed(() => {
  const options = [
    {
      text: t('flowModule.folders.actions.rename'),
      icon: 'pen',
      dataTestId: 'folderRenameOption',
      action: () => emit('rename'),
    },
    {
      text: t('flowModule.folders.actions.delete'),
      icon: 'trash',
      dataTestId: 'folderDeleteOption',
      action: () => emit('delete'),
    },
  ];

  return options;
});

const getDropdownTestId = computed(() => {
  return `${camelCase(`${props.folder.name}Dropdown`)}`;
});
</script>

<template>
  <XDropdownMenu
    :options="optionsDropdown"
    placement="bottom-end"
  >
    <template #activator="{ on: openDropdown }">
      <XButton
        border-radius="circle"
        design="text"
        size="xs"
        :data-testid="getDropdownTestId"
        @click="openDropdown"
      >
        <XIcon icon="ellipsis-v" />
      </XButton>
    </template>
  </XDropdownMenu>
</template>
