<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute, useModal, useToast } from '@base/hooks';
import { camelizeKeys } from '@base/utils';
import { useFormFlowListActions } from '@flow/composables/useFormFlowListActions';
import { useFormFlowListFilters } from '@flow/composables';
import { FormFlowEmptyList, SearchExistingFlowsModal } from '@flow/components/form-flow';
import { useI18n } from '@/locales';
import { useStore } from '@/store';
import { FLOW_CONTEXT_NAMES_CONSTANTS } from '@flow/constants';
import { FormFlowListContainer } from '@flow/containers/form-flow';

const store = useStore();
const route = useRoute();
const { handleNew } = useFormFlowListActions();
const { hasFilters } = useFormFlowListFilters();
const { isModalActive, openModal, closeModal } = useModal();
const toast = useToast();
const { t } = useI18n();

const loading = ref(true);

const flowFolder = computed(() => store.getters['flowFolder/getFolder']);
const totalItems = computed(() => store.getters['formFlow/getTotalItems']);
const flowFoldersLink = computed(() => store.getters['info/getRoutes'].flowFolders);
const showEmptyView = computed(() => totalItems.value === 0 && !hasFilters.value && !loading.value);

const getQueryString = (routeQuery) => {
  const queryString = Object.entries(routeQuery)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  return queryString;
};

const fetchFolderAndFormFlows = async (fetchFolder = true) => {
  loading.value = true;
  store.commit('formFlow/SET_LOADING', true);

  try {
    if (fetchFolder) {
      await store.dispatch('flowFolder/fetchFlowFolder', { link: window.location.href });
    }

    store.commit('formFlow/SET_LINKS', {
      self: `${flowFolder.value.links.forms}&${getQueryString(route.query)}`,
    });
    await store.dispatch('formFlow/fetchFormFlows', camelizeKeys(route.query));
  } catch (err) {
    toast.error(t('flowModule.folders.error.fetch'));
  } finally {
    loading.value = false;
    store.commit('formFlow/SET_LOADING', false);
  }
};

const handleMove = async ({ flowFormIds }) => {
  try {
    await store.dispatch('flowFolder/addFlowsToFolder', { flowFormIds });
    fetchFolderAndFormFlows(false);
    toast.success(t('flowModule.folders.searchExistingFlows.successMessage'));
    closeModal();
  } catch (error) {
    toast.error(t('flowModule.folders.searchExistingFlows.errorMessage'));
  }
};

onMounted(() => {
  fetchFolderAndFormFlows();
});
</script>

<template>
  <section :class="$style.wrapper">
    <div :class="$style.header">
      <XLink :to="flowFoldersLink">
        <XIcon
          icon="arrow-left"
          size="lg"
          left
        />
        {{ $t('general.action.back') }}
      </XLink>
      <XButton
        data-testid="createFlowButton"
        data-ga-event="button-create-flow-click"
        @click="handleNew(flowFolder.id)"
      >
        {{ $t('flowModule.new') }}
      </XButton>
    </div>
    <h1
      :class="$style.title"
      :title="flowFolder.name"
      data-testid="pageTitle"
    >
      {{ flowFolder.name }}
    </h1>
    <XCard v-if="showEmptyView">
      <FormFlowEmptyList
        icon="folder"
        :title="$t('flowModule.folders.emptyFlowList.title')"
        :description="$t('flowModule.folders.emptyFlowList.description')"
        :action-text="$t('flowModule.new')"
        :secondary-action-text="$t('flowModule.folders.emptyFlowList.searchExistingFlows')"
        secondary-action-design="outlined"
        data-testid="folderEmptyFlowList"
        @handle-secondary-action="openModal('SearchExistingFlowsModal')"
        @handle-new="handleNew(flowFolder.id)"
      />
    </XCard>
    <FormFlowListContainer
      v-else
      :context="FLOW_CONTEXT_NAMES_CONSTANTS.folder"
    />
    <SearchExistingFlowsModal
      :show-modal="isModalActive('SearchExistingFlowsModal')"
      @close="closeModal"
      @handle-move="handleMove"
    />
  </section>
</template>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  margin: var(--space-large);
  gap: var(--space-regular);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--color-brand-secondary-400);
  }
}
</style>
