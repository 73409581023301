import { getViewProps } from '@base/utils/getViewDataset';

const routes = [
  {
    path: '/operator',
    component: () => import('@users/views/UserProfileView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      refresh: () => true,
    },
    props: getViewProps,
  },
  {
    view: 'MultiFactorSettingsContainer',
    component: () => import('@operators/containers/MultiFactorSettingsContainer.vue'),
  },
  {
    view: 'EmailValidationView',
    path: '/operator/confirmation_email',
    name: 'operatorEmailConfirmation',
    component: () => import('@operators/views/EmailValidation/EmailValidationView.vue'),
  },
  {
    view: 'BlockedRegionView',
    path: '/operator/blocked_region',
    name: 'operatorBlockedRegion',
    component: () => import('@operators/views/BlockedRegion/BlockedRegionView.vue'),
    meta: {
      layout: 'BaseDefaultLayout',
      refresh: () => true,
    },
  },
];

export default routes;
