const signature = {
  title: 'Signatures',
  loading: 'Loading subscription page…',
  sign: 'Sign documents | Sign | Sign {documentsCount} documents',
  info: '{signedCount}/{signersCount} Signatures',
  waiting:
    'Signing document ... | Signing document ... | Signing document {signedDocuments} of {totalDocuments} ...',
  massiveSignature: {
    signedFromTotal: 'You have already signed {signedDocuments} of {totalDocuments} documents.',
    loadingNextBatch: 'Loading next batch...',
  },
  security: 'Secure environment Clicksign',
  step: {
    start: 'Start',
    active: 'Step {stepActiveIndex} of {stepsCount}',
    next: 'Next',
    advance: 'Next',
    finalize: 'Submit',
    refusal: 'Refuse to sign',
    report: 'Report an issue',
    termsOfAgreement: {
      text: "By clicking Submit you agree to Clicksign's {0} and {1}.",
      linkText: 'Terms of use',
    },
    privacyPolicy: 'Privacy Policy - LGPD',
    index: {
      title: "Let's sign!",
      subtitle: 'Follow the steps below to complete the signature.',
      presentialSignatureSubtitle: 'Follow the steps below to complete the signature.',
      positionSign: 'Position yout signature.',
      signerInfo: 'Your information.',
      selfieConfirmation: 'Picture of face with official document.',
      addressProof: 'Proof of address.',
      handWrittenConfirmation: 'Handwritten signature.',
      tokenConfirmation: 'Authentication token.',
      officialDocument: 'Official document photo.',
      pixConfirmation: 'Pix Authentication.',
      livenessConfirmation: 'Dynamic selfie.',
      icpBrasilConfirmation: 'Digital Certificate',
      facialBiometricsConfirmation: '@:steps.facialBiometrics.title',
      signerLocation: 'Location',
      voiceBiometrics: 'Voice biometrics',
    },
    info: {
      title: 'Confirm your information',
      form: {
        fullName: 'Full name',
        documentation: 'Brazilian Social Security Number (CPF)',
        documentationPlaceholder: '___.___.___-__',
        birthday: 'Date of Birth (MM/DD/YYYY)',
        birthdayPlaceholder: '__/__/____',
        email: 'E-mail address (to receive the signed document)',
        emailInfo: 'The e-mail will be sent when all the signers complete the signing process',
        emailPlaceholder: 'Enter your e-mail address',
      },
    },
    expiredLink: {
      errorLabel: 'error 410',
      title: 'This link has expired',
      subtitle:
        'Check if this document has already been signed or if the signature deadline has expired',
      description:
        'If you recently received the email, you may be trying to access a document that has already been signed. If not, check whether the subscription deadline has expired.',
      otherCase:
        "If this doesn't apply to you, try contacting the document sender to find out why the signature expired.",
      help: 'Need Help? Visit our {0} or send an email to {1}.',
      helpCenter: 'Help central',
      helpCenterTitle: 'Click to access the help central',
      helpEmail: 'ajuda@clicksign.com',
      helpEmailTitle: 'Click to send email',
      altClicksignLogo: 'Clicksign Logo',
    },
    token: {
      title: 'Authentication token',
      subtitle: 'We send a token code to',
      label: {
        default: 'Enter the token to finish',
        error: 'Invalid token',
        insert: 'Insert Token and complete the signature',
        presetToken: 'Now you just need to finish the signature',
      },
      tokenSent: 'Sent to {origin}',
      invalid: 'Invalid token',
      automatic: 'Token was filled in automatically',
      tooltip: 'The Token was filled in automatically to facilitate the signing of the document.',
      help: 'Did not receive the token?',
      resend: 'Resend token by',
      resendTo: {
        whatsapp: 'WhatsApp',
        email: 'e-mail',
        sms: 'SMS',
      },
      limitExceeded: 'Retry limit exceeded.',
      limitExceededDescription: '{0} Try again in {1}.',
      email: 'e-mail',
      sms: 'SMS',
      whatsapp: 'WhatsApp',
      authenticated: 'Authenticated token',
      expired: '{0} Validate again in the last step.',
      expiredBold: 'Expired Token:',
      sentTo: {
        email: 'We sent a token to e-mail',
        sms: 'We sent a token to SMS',
        whatsapp: 'We sent a token to Whatsapp',
      },
      send: 'Send Token by',
      resending: 'Sending Token...',
      timer: 'Wait {timer} to resend',
      remainingAttempts:
        'Only {remainingAttempts} Token sending attempt remains| There are only {remainingAttempts} Token send attempts left',
      noMoreAttempts: 'Limit of attempts to send Token reached',
      maxAttemptsHelp: 'Problems completing authentication?',
      sentSuccess: 'Token sent successfully!',
      resentSuccess: 'Token resent successfully!',
      finalize: 'Finalize',
    },
    presential: {
      title: "Let's start your signature",
      subtitle: 'Insert your access token',
      successToken: 'Token was automatically verified',
      action: 'Start sign',
      expired: {
        title: 'This code has expired',
        subtitle: 'Request a new sign code from the person who managed your service.',
        action: 'Return to start',
        return: 'Return in {time} seconds...',
      },
      validation: {
        title: 'Validating your access token',
      },
      termsOfAgreement: {
        text: 'by clicking next you agree with Clicksign {0} and {1}.',
      },
    },
    pix: {
      title: "Open your bank's app",
      copyAndPasteText: 'and use the "Pix copy and paste" option',
      qrCodeAlternativeText: 'Or pay with QR Code',
      copyLabel: 'or use the "Pix copy and paste" option',
      actions: {
        copy: 'Copy Pix Code',
        copied: 'Copied',
        close: 'Close',
      },
      disclaimer: 'Use your bank account to pay',
      help: 'Difficulties with Pix?',
      whyPix: {
        action: 'Why Pix?',
        modal: {
          title: 'To sign the document, Pix must be registered in your bank account.',
          whatIsPixTitle: 'What is Pix?',
          whatIsPixDescription:
            'Pix is ​​a fast and secure instant payment system created by Brazilian Central Bank.',
          whySignWithPixTitle: 'Why sign with Pix?',
          whySignWithPixDescription:
            'When you pay with Pix, we are able to authenticate your data.',
          whySignWithPixDescriptionTwo:
            'This authentication decreases the chance of fraud, making the electronic signature more secure.',
          senderAuthMethod:
            '*This signature authentication method was chosen by the sender of this document.',
        },
      },
      feedbackModal: {
        title: 'What is your difficulty?',
        options: {
          dontHavePIX: "I don't have Pix",
          cantPay: "I can't do the transaction now",
          dontWantToSign: "I don't want to sign with Pix",
          cantUnderstand: "I couldn't understand how it works",
          issuesWithMyBank: 'I had a technical problem',
          other: 'Others',
        },
        actions: {
          next: 'Next',
          cancel: 'Cancel',
        },
      },
      helpModal: {
        linkText: 'See how to proceed here',
        description: 'Please, inform the sender in case you don’t finalize the document signature.',
        close: 'Close',
      },
      loading: 'Loading QR Code...',
      subtitle: 'and use option {0}',
      qrCode: 'QR Code',
      pixCopyAndPaste: 'Pix copy and paste',
      cash: 'R$ {0}',
      alternativeSubtitle: 'Or use option {0}',
      payWith: 'Or pay with {0}',
      tutorial: {
        confirm: {
          title: "Confirm it's you",
          subtitle: 'Through bank authentication.',
        },
        pay: {
          title: 'Do a bank transfer of R$ 0,01 with Pix',
          subtitle: 'Use the Pix registered in your bank account.',
        },
        sign: {
          title: 'And sign with safety',
          subtitle: 'The transaction confirms your data and reduces the risk of fraud.',
        },
      },
      next: 'Next',
      signWithPix: 'Sign with Pix',
      pixFailed: {
        title: 'We couldn’t recognize you',
        cpfInformed:
          'It seems that the CPF informed is not the same as the bank account used to make the Pix.',
        dontWorry: "Don't worry, the amount will be returned to your account.",
        tryAgain: 'Try again with a Pix registered in your bank account.',
        actions: {
          tryAgain: 'Try again',
        },
      },
    },
    selfie: {
      analyzingSelfie: 'Analyzing a photo of your face and document',
      prepare: {
        title: 'Picture of your face holding Photo ID Document',
        takePhoto: 'Take a picture',
        disclaimer: 'Your photo will be protected by a watermark.',
        documentAllowed: 'Can be an official document.',
        dontCoverYourFace: "Be careful, don't cover your face with the document.",
      },
      photo: {
        title: 'If {0} seem clear, proceed to the next step.',
        boldTitle: 'the picture of your face with the document',
        face: 'Face',
        takeAnother: 'Take another picture',
      },
      notApproved: {
        title: 'Photo not recognized.',
        newSelfie: 'Please take a new selfie with your identification document.',
        desc: 'Ensure that the image is clear and remember not to wear any acessories.',
        tryAgain: 'Try again',
      },
      error: {
        browserIncompatible: {
          name: 'Your browser does not support photo capature.',
          description: 'Try signing with another browser.',
        },
        cameraMissing: {
          name: 'Could not find a camera.',
          description: 'Sign with another device, try a smartphone.',
        },
        cameraBlocked: {
          name: 'Permission denied to camera.',
          description: 'Try to enable it and reload this page.',
        },
        cameraBusy: {
          name: 'Your camera is in use by another application.',
          description: 'Close other applications and try again.',
        },
        selfie: {
          name: 'Unexpected error.',
          description: 'Reload the page and try again.',
        },
      },
    },
    handwritten: {
      title: 'Draw your handwritten signature',
      clear: 'Clear',
      disclaimer: 'Your handwritten signature will be protected by a watermark.',
      positionSignDisclaimer: 'Your handwritten signature will be watermarked',
      reusableDisclaimer: 'Signature obtained from last use',
    },
    positionSign: {
      nextDocument: 'Next document',
    },
    officialDocument: {
      takePhoto: 'Take a picture',
      next: 'Next',
      takeAnother: 'Take another picture',
      prepare: {
        titleFront: 'Front photo of the document',
        titleBack: 'Photo of the back document',
        disclaimer: {
          useOfficialDocument:
            'Photo of the official document, for example Passport, Drive license, ID document.',
          removeFromPlastic: 'If possible, remove the plastic.',
        },
      },
      photo: {
        title: 'If the photo {0}, continue to the next step.',
        titleBoldFront: 'on the front of the document is clear',
        titleBoldBack: 'on the back of the document is clear',
        front: 'Front',
        back: 'Back',
      },
    },
  },
  facematchPending: {
    title: 'Document signature in process',
    description: 'Your images are being processed. You will receive an email when it is complete.',
  },
  facematchRefused: {
    title: 'Signature not performed',
    description: "We haven't identified you yet. Try again, maybe using another document.",
    button: 'Try again',
  },
  alreadySigned: {
    title: 'You have already signed this document.',
    description: {
      anyDocumentsPending:
        'Once everyone has signed, we will send the signed document to your email. You can also access Clicksign to track this signing process and download the signed document as soon as it is available.|Once everyone has signed, we will send the signed documents to your email. You can also access Clicksign to track this signing process and download the signed documents as soon as they are available.',
      allDocumentsClosed:
        'We have sent the signed document to your email. You can also access Clicksign to download it.|We have sent the signed documents to your email. You can also access Clicksign to download them.',
    },
  },
  success: {
    title: 'Successfully signed!',
    dashboardArea: 'Back to my signatures',
    description:
      'When everyone signs up, you will receive an email with the signed document | When everyone signs up, you will receive an email with the signed documents.',
    smartphoneImageAlt: 'Smartphone displaying the Clicksign document signing page',
    pix: 'You Pix was received and you are authenticated.',
    cta: {
      user: {
        title: 'Your documents in one place',
        description:
          'Access your Clicksign account to sign and download your documents in one place.',
      },
      noUser: {
        title: 'Sign and order online signatures with legal validity',
        description: 'Create a Clicksign account to sign your documents in one place.',
      },
      advantagesTitle: 'Check out the advantages of signing with Clicksign:',
      advantages: {
        digitalAndElectronicSignature: 'Digital and electronic signatures',
        multipleAuthKinds: 'Different types of Authentication',
        whatsappSmsAndEmail: 'Sending via WhatsApp, SMS or email',
        acceptancesAndDocumentsManagement: 'Documents management',
      },
      createAccount: 'Create free account',
      accessClicksign: 'Access Clicksign',
      hasPendenciesTitle: 'There are still documents to sign',
      reusableManuscript: {
        title: 'To save your signature, sign up or log in to sign.',
        description:
          'When you sign a document while logged in to Clicksign, we save your handwritten signature so you can use it in your next documents.',
        link: 'Understand about saving handwritten signature',
      },
    },
  },
  failed: {
    title: 'Signature not performed',
    subtitle: 'We were unable to complete the signature.',
  },
  error: {
    conflict: 'There are no documents to be signed.',
    connection: 'Connection error. Try again later!',
    forbidden: 'Invalid token!',
    validation: 'Validation error',
    default: 'Unexpected error. Try again later!',
    invalidTokenRateLimit: 'Retry limit exceeded. Wait 10 minutes to try again.',
    unprocessed: 'Error processing signature',
    location: 'Location is required.',
  },
  pending: {
    pendingDocumentsCounter: '{pendingCounter} document | {pendingCounter} documents',
    titleSingle: 'You still have {0} waiting to be signed',
    titleMultiple: 'You still have {0} waiting to be signed',
    action: 'Sign document | Sign documents',
    // FIXME: After fix component 'WidgetPendingDocumentsWarning' use in refusal feedback.
    // TODO: Remove titleOld and actionOld
    titleOld:
      'There are still {pendingDocumentsCount} document awaiting your signature. | There are still {pendingDocumentsCount} documents awaiting your signature.',
    actionOld: 'Preview to sign',
  },
  kind: {
    sign: 'Sign',
    approve: 'Sign to approve',
    party: 'Sign as party',
    witness: 'Sign as witness',
    intervening: 'Sign as intervening party',
    receipt: 'Sign to acknowledge receipt',
    endorser: 'Sign as endorser',
    endorsee: 'Sign as endorsee',
    administrator: 'Sign as administrator',
    guarantor: 'Sign as guarantor',
    transferor: 'Sign as transferor',
    transferee: 'Sign as transferee',
    contractee: 'Sign as contractee',
    contractor: 'Sign as contractor',
    jointDebtor: 'Sign as joint debtor',
    issuer: 'Sign as issuer',
    manager: 'Sign as manager',
    buyer: 'Sign as buyer',
    seller: 'Sign as seller',
    attorney: 'Sign as attorney-in-fact',
    legalRepresentative: 'Sign as legal representative',
    coResponsible: 'Sign as co-responsible',
    validator: 'Sign as validator',
    surety: 'Sign as surety',
    lessor: 'Sign as lessor',
    lessee: 'Sign as lessee',
    ratify: 'Sign to ratify',
    employer: 'Sign as employer',
    employee: 'Sign as employee',
    consenting: 'Sign as consenting',
    accountant: 'Sign as accountant',
    debtor: 'Sign as debtor',
    franchisee: 'Sign as franchisee',
    creditor: 'Sign as creditor',
    franchisor: 'Sign as franchisor',
    insured: 'Sign as insured',
    grantor: 'Sign as grantor',
    president: 'Sign as president',
    partner: 'Sign as partner',
    comforter: 'Sign as comforter',
    accountHolder: 'Sign as account',
    distracted: 'Sign as distracted',
    distracting: 'Sign as distracting',
    lender: 'Sign as lender',
    associate: 'Sign as associate',
    broker: 'Sign as broker',
    bailee: 'Sign as bailee',
    collateralProvider: 'Sign as guarantor',
    pledged: 'Sign as pledged',
    consignee: 'Sign as consignee',
    lawyer: 'Sign as lawyer',
    grantee: 'Sign as grantee',
    borrower: 'Sign as borrower',
    guarantorSpouse: "Sign as guarantor' spouse",
    legalGuardian: 'Sign as legal guardian',
    director: 'Sign as director',
    realEstateBroker: 'Sign as real estate broker',
    insuranceBroker: 'Sign as insurance broker',
    licensor: 'Sign as licensor',
    licensed: 'Sign as licensed',
    serviceProvider: 'Sign as service provider',
    secured: 'Sign as secured',
    consented: 'Sign as consented',
    interveningGuarantor: 'Sign as intervening guarantor',
    consentingIntervenor: 'Sign as consenting intervenor',
    surveyor: 'Sign as surveyor',
    buildingManager: 'Sign as building manager',
    intermediary: 'Sign as intermediary',
    condominiumMember: 'Sign as condominium member',
    owner: 'Sign as owner',
    resident: 'Sign as resident',
    treasurer: 'Sign as treasurer',
    secretary: 'Sign as secretary',
    signedAsSign: 'Signed',
    signedAsApprove: 'Signed to approve',
    signedAsParty: 'Signed as party',
    signedAsWitness: 'Signed as witness',
    signedAsIntervening: 'Signed as intervening party',
    signedAsReceipt: 'Signed to acknowledge receipt',
    signedAsEndorser: 'Signed as endorser',
    signedAsEndorsee: 'Signed as endorsee',
    signedAsAdministrator: 'Signed as administrator',
    signedAsGuarantor: 'Signed as guarantor',
    signedAsTransferor: 'Signed as transferor',
    signedAsTransferee: 'Signed as transferee',
    signedAsContractee: 'Signed as contractee',
    signedAsContractor: 'Signed as contractor',
    signedAsJointDebtor: 'Signed as joint debtor',
    signedAsIssuer: 'Signed as issuer',
    signedAsManager: 'Signed as manager',
    signedAsBuyer: 'Signed as buyer',
    signedAsSeller: 'Signed as seller',
    signedAsAttorney: 'Signed as attorney-in-fact',
    signedAsLegalRepresentative: 'Signed as legal representative',
    signedAsCoResponsible: 'Signed as co-responsible',
    signedAsValidator: 'Signed as validator',
    signedAsSurety: 'Signed as surety',
    signedAsLessor: 'Signed as lessor',
    signedAsLessee: 'Signed as lessee',
    signedAsRatify: 'Signed to ratify',
    signedAsEmployer: 'Signed as employer',
    signedAsEmployee: 'Signed as employee',
    signedAsConsenting: 'Signed as consenting',
    signedAsAccountant: 'Signed as accountant',
    signedAsDebtor: 'Signed as debtor',
    signedAsFranchisee: 'Signed as franchisee',
    signedAsCreditor: 'Signed as creditor',
    signedAsFranchisor: 'Signed as franchisor',
    signedAsInsured: 'Signed as insured',
    signedAsGrantor: 'Signed as grantor',
    signedAsPresident: 'Signed as president',
    signedAsPartner: 'Signed as partner',
    signedAsComforter: 'Signed as comforter',
    signedAsAccountHolder: 'Signed as account holders',
    signedAsDistracted: 'Signed as distracted',
    signedAsDistracting: 'Signed as distracting',
    signedAsLender: 'Signed as lender',
    signedAsAssociate: 'Signed as associate',
    signedAsBroker: 'Signed as broker',
    signedAsBailee: 'Signed as bailee',
    signedAsCollateralProvider: 'Signed as guarantor',
    signedAsPledged: 'Signed as pledged',
    signedAsConsignee: 'Signed as consignee',
    signedAsLawyer: 'Signed as lawyer',
    signedAsGrantee: 'Signed as grantee',
    signedAsLoanee: 'Signed as loanee',
    signedAsBorrower: 'Signed as borrower',
    signedAsGuarantorSpouse: "Signed as guarantor' spouse",
    signedAsLegalGuardian: 'Signed as legal guardian',
    signedAsDirector: 'Signed as director',
    signedAsRealEstateBroker: 'Signed as real estate broker',
    signedAsInsuranceBroker: 'Signed as insurance broker',
    signedAsLicensor: 'Signed as licensor',
    signedAsLicensed: 'Signed as licensed',
    signedAsServiceProvider: 'Signed as service provider',
    signedAsSecured: 'Signed as secured',
    signedAsConsented: 'Signed as consented',
    signedAsInterveningGuarantor: 'Signed as intervening guarantor',
    signedAsConsentingIntervenor: 'Signed as consenting intervenor',
    signedAsSurveyor: 'Signed as surveyor',
    signedAsBuildingManager: 'Signed as building manager',
    signedAsIntermediary: 'Signed as intermediary',
    signedAsCondominiumMember: 'Signed as condominium member',
    signedAsOwner: 'Signed as owner',
    signedAsResident: 'Signed as resident',
    signedAsTreasurer: 'Signed as treasurer',
    signedAsSecretary: 'Signed as secretary',
  },

  photo: {
    confirmPhoto: 'I confirm that the photo is correct',
  },
};

export default signature;
