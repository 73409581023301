const voiceBiometrics = {
  allowVoice: {
    title: 'Permita acesso ao microfone',
    description: 'Este é um requisito de segurança deste processo.',
    next: 'Avançar',
    error: 'Ative o microfone em seu navegador',
    help: 'Saiba mais',
  },
  captureVoice: {
    helpTitle: 'Gravação da voz',
    helpDescription: 'Repita até três vezes a frase indicada para cadastrarmos a sua voz.',
    title: 'Clique no botão e fale a frase a seguir:',
    phrase: '"Sou reconhecido pela minha voz"',
    sending: 'Enviando áudio para reconhecimento...',
    recording: 'Gravando...',
    help: 'Saiba mais',
  },
  feedback: {
    success: {
      title: 'Voz reconhecida com sucesso!',
    },
    error: {
      title: 'Ainda não reconhecemos sua voz',
      description: {
        error: 'Por favor, tente novamente',
        audioTooShort: 'O áudio está muito curto. Por favor, repita a frase.',
        wrongPassphrase: 'A frase falada não corresponde à solicitada. Por favor, repita a frase.',
        audioTooNoisy:
          'O áudio está muito ruidoso. Por favor, repita a frase em um ambiente mais silencioso.',
      },
    },
    moreAudioRequired: {
      title: 'Cadastro da voz realizado com sucesso',
      description: 'Mas ainda precisamos de uma gravação para garantir a sua segurança.',
    },
    succeeded: {
      title: 'Cadastro da voz realizado com sucesso',
      description: 'Agora você está ainda mais seguro ao realizar as suas transações com a VIVO.',
    },
    nextButton: 'Avançar',
    tryAgainButton: 'Tentar novamente',
  },
};

export default voiceBiometrics;
