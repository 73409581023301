<script setup>
import { useFormFlowPendingProcesses } from '@flow/composables';

defineProps({
  showModal: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['close']);

const {
  hasPendingProcesses,
  hasWaitingSecondFormProcesses,
  pendingProcesses,
  waitingSecondFormProcesses,
  verifyPendingProcessesLinks,
} = useFormFlowPendingProcesses();
</script>

<template>
  <XModal
    :is-open="showModal"
    :title="$t('flowModule.bulkActions.pendingProcessesModal.title')"
    data-testid="formFlowPendingProcessesModalContainer"
    @close="$emit('close')"
  >
    <template #default>
      <p :class="$style.text">
        {{ $t('flowModule.bulkActions.pendingProcessesModal.description') }}
      </p>

      <div :class="$style.wrapper">
        <XCard
          v-if="hasPendingProcesses"
          :class="$style.card"
        >
          <span :class="$style.title">
            {{ $t('flowModule.bulkActions.pendingProcessesModal.pendingProcesses.title') }}
          </span>
          <ul :class="$style.list">
            <li
              v-for="(formFlow, formFlowIndex) in pendingProcesses"
              :key="`pendingProcesses${formFlowIndex}`"
            >
              {{ formFlow.name }}
            </li>
          </ul>
          <XLink :to="verifyPendingProcessesLinks?.pendingProcesses">
            {{ $t('flowModule.bulkActions.pendingProcessesModal.pendingProcesses.link') }}
          </XLink>
        </XCard>
        <XCard
          v-if="hasWaitingSecondFormProcesses"
          :class="$style.card"
        >
          <span :class="$style.title">
            {{
              $t('flowModule.bulkActions.pendingProcessesModal.waitingSecondFormProcesses.title')
            }}
          </span>
          <ul :class="$style.list">
            <li
              v-for="(formFlow, formFlowIndex) in waitingSecondFormProcesses"
              :key="`waitingSecondFormProcesses${formFlowIndex}`"
            >
              {{ formFlow.name }}
            </li>
          </ul>
          <XLink :to="verifyPendingProcessesLinks?.waitingSecondFormProcesses">
            {{ $t('flowModule.bulkActions.pendingProcessesModal.waitingSecondFormProcesses.link') }}
          </XLink>
        </XCard>
      </div>
    </template>
  </XModal>
</template>

<style lang="scss" module>
.text {
  margin-bottom: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-regular);
  gap: var(--space-regular);

  .card {
    font-size: var(--font-size-small);
    background-color: var(--color-neutral-50);

    .title {
      font-size: var(--font-size-regular);
      font-weight: var(--font-weight-bold);
    }

    .list {
      margin: var(--space-small-xxx) 0;
      padding-left: var(--space-medium);

      li {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
