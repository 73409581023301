import moment from 'moment';
import { maskText } from '@base/utils/mask';
import { MASKS } from '@base/constants/mask';
import { camelCase, pick, snakeCase } from 'lodash';

export const buildAuthsList = (signer) => {
  if (_.isEmpty(signer)) return signer;
  const additionalAuthsKeys = [
    'officialDocumentEnabled',
    'selfieEnabled',
    'handwrittenEnabled',
    'livenessEnabled',
    'facialBiometricsEnabled',
  ]
    .filter((auth) => signer[auth])
    .map((auth) => auth.replace('Enabled', ''));
  const auths = _.castArray(signer.auths);
  if (signer.authentications?.length > 0) {
    return signer.authentications.map(camelCase);
  }
  return auths ? auths.concat(additionalAuthsKeys) : [];
};

export const parseListPayload = (payload) => ({
  lists: _.castArray(payload.list),
  message: payload.message,
});

export const parseContactsToSigners = (contacts) =>
  contacts.map((contact) => ({
    ...contact,
    id: contact.signerId,
    key: contact.signerKey,
  }));

export const parseFlexibeContactsToSigners = (contacts, context) =>
  contacts.map((contact) => {
    if (context === 'FLOW_FLEXIBLE_CONTACT') {
      if (contact.authentications.length === 0) {
        return {
          ...contact,
          contact: true,
          signerId: contact.signerId,
          contactId: contact.id,
          key: contact.signerKey,
          context,
          auths: contact.authsList,
        };
      }
      return {
        ...contact,
        contact: true,
        signerId: contact.signerId,
        contactId: contact.id,
        context,
        key: contact.signerKey,
      };
    }
    if (contact.authentications.length === 0) {
      return {
        ...contact,
        contact: true,
        id: contact.signerId || contact.id,
        key: contact.signerKey,
        context,
        auths: contact.authsList,
      };
    }
    return {
      ...contact,
      contact: true,
      id: contact.signerId || contact.id,
      context,
      key: contact.signerKey,
    };
  });

export const parsePhoneNumber = (payload) => {
  const parsedPhoneNumber = _.replace(payload.phoneNumber, /[^\d]/g, '') || undefined;
  return { ...payload, phoneNumber: parsedPhoneNumber };
};

export const buildContactRequest = (contact) => {
  const parsedContact = parsePhoneNumber(contact);
  const auths = contact.auths ? _.castArray(contact.auths).map(snakeCase) : undefined;
  return {
    ...parsedContact,
    authentications: undefined,
    auths,
  };
};

export const buildFlexibleContactRequest = (contact) => {
  const parsedContact = parsePhoneNumber(contact);
  const parsedContactWithNoAuths = pick(parsedContact, [
    'communicateBy',
    'email',
    'phoneNumber',
    'name',
    'hasDocumentation',
    'documentation',
    'birthday',
  ]);

  return {
    ...parsedContactWithNoAuths,
    authentications: contact.auths.map(snakeCase),
  };
};

export const flattenSigners = (signers) =>
  _.reduce(
    signers,
    (map, value) => {
      const toFlattenSigner = _.find(Object.values(map), (signer) => signer.id === value.id) || {
        ...value,
        signAs: [],
      };
      const signAs = _.castArray(toFlattenSigner.signAs);
      const flattedSigner = { ...toFlattenSigner, signAs: [...signAs, value.signAs] };
      return _.unionBy([flattedSigner], map, 'id');
    },
    []
  );

export const parseSigner = (signer, context) => {
  if (_.isEmpty(signer)) return signer;

  const authsList = buildAuthsList(signer);
  Object.assign(
    signer,
    { authsList },
    signer.birthday && { birthday: moment(signer.birthday).format('DD/MM/YYYY') },
    context && { context }
  );
  // TOGGLE_FLOW_WITH_FLEXIBLE_AUTH_ENABLED
  // TODO: remove FORM_SIGNER logic below
  if (context !== 'FORM_SIGNER' && context !== 'FORM_SIGNER_FLEXIBLE') {
    const maskedPhoneNumber = maskText(signer.phoneNumber, MASKS.phone) || undefined;
    Object.assign(signer, { phoneNumber: maskedPhoneNumber });
  }
  return signer;
};

export const parseLinkSignerResponse = (response, context) => {
  if (_.isEmpty(response)) return response;
  const contextParsers = {
    DEFAULT(payload) {
      const dataParamsByContext = {
        DOCUMENT_SIGNER: 'lists',
        SPREADSHEET_FLOW_SIGNER: 'spreadsheet_flow_signer_lists',
        FLOW_FORM_SIGNER: 'flow_lists',
        FLOW_FLEXIBLE_CONTACT: 'flow_lists',
        FLOW_FLEXIBLE_SIGNER: 'flow_lists',
        DRAFT_SIGNER: 'lists',
      };
      const dataParam = dataParamsByContext[context];
      const signers = _.chain(payload)
        .get(dataParam, payload.signers)
        .castArray()
        .map(parseSigner)
        .filter((notEmpty) => notEmpty)
        .value();
      return flattenSigners(signers);
    },
    FORM_SIGNER(payload) {
      return payload;
    },
    SPREADSHEET_SIGNER(payload) {
      return payload;
    },
    DRAFT_SIGNER(payload) {
      return payload;
    },
  };
  const parseFunction = contextParsers[context];
  return parseFunction ? parseFunction(response) : contextParsers.DEFAULT(response);
};

export const buildSignAsListRequest = (signAsForm, context) => {
  const contextBuilders = {
    DEFAULT(payload) {
      const dataParamsByContext = {
        DOCUMENT_SIGNER: 'lists',
        SPREADSHEET_FLOW_SIGNER: 'spreadsheet_flow_signer_lists',
        FLOW_FORM_SIGNER: 'flow_lists',
        DRAFT_SIGNER: 'lists',
      };
      const dataParam = dataParamsByContext[context];
      const signers = _.get(payload, 'signers') || [];
      const signAsList = _.get(payload, 'signAsList');

      const buildedSignAsList = Object.keys(signAsList).map((signerId) => {
        const signAsTextList = _.castArray(signAsList[signerId]);
        const features = _.find(signers, { id: Number(signerId) })?.features;
        return signAsTextList.map((signAs) => ({
          signerId,
          signAs,
          ...(features || {}),
        }));
      });
      const lists = _.flatten(buildedSignAsList);
      return { [dataParam]: lists };
    },
    FLOW_FLEXIBLE_SIGNER(payload) {
      const signers = _.get(payload, 'signers') || [];
      const signAsList = _.get(payload, 'signAsList');
      const signerList = signers.map((signer) => {
        let roles = [];
        if (signer.id in signAsList) {
          roles = signAsList[signer.id];
        }
        const signerAttributes = {
          name: signer.name,
          email: signer.email,
          phoneNumber: signer.phoneNumber,
          hasDocumentation: signer.hasDocumentation,
          documentation: signer.documentation,
          birthday: signer.birthday,
          communicateBy: signer.communicateBy,
        };
        const signerAuths = signer.authentications || signer.auths;
        return {
          refusable: signer.features?.refusable,
          contactId: signer.id,
          provideEvidences: signerAuths.map(snakeCase) || [],
          roles: roles.map(snakeCase) || [],
          signerAttributes,
        };
      });
      return { flow_lists: signerList };
    },
    FLOW_FLEXIBLE_CONTACT(payload) {
      const signers = _.get(payload, 'signers') || [];
      const signAsList = _.get(payload, 'signAsList');
      const signerList = signers.map((signer) => {
        let roles = [];
        if (signer.id in signAsList) {
          roles = signAsList[signer.id];
        }
        const signerAuths = signer.authentications || signer.auths;
        return {
          signerId: signer.signerId,
          contactId: signer.contactId,
          refusable: signer.features?.refusable,
          provideEvidences: signerAuths.map(snakeCase) || [],
          roles: roles.map(snakeCase) || [],
        };
      });
      return { flow_lists: signerList };
    },
    FORM_SIGNER(payload) {
      const {
        hasDocumentation,
        communicateBy,
        auths,
        phoneNumber,
        email,
        selfieEnabled,
        officialDocumentEnabled,
        handwrittenEnabled,
        livenessEnabled,
        facialBiometricsEnabled,
        features,
      } = payload.signers[0];
      const flowSigners = {
        hasDocumentation,
        facialBiometricsEnabled,
        livenessEnabled,
        handwrittenEnabled,
        officialDocumentEnabled,
        selfieEnabled,
        fieldId: email,
        formFieldWhatsappId: phoneNumber,
        auths: [auths],
        communicateBy,
        refusable: features?.refusable,
      };
      return {
        flowSigners: payload.signAsList[flowSigners.fieldId || flowSigners.formFieldWhatsappId].map(
          (signAs) => {
            return {
              ...flowSigners,
              signAs,
            };
          }
        ),
      };
    },
    FORM_SIGNER_FLEXIBLE(payload) {
      const signer = payload.signers[0];
      const { signAsList } = payload;

      const roles = Object.values(signAsList)[0];
      const signerAuths = signer.authentications || signer.auths;
      return {
        flowSigners: [
          {
            ...buildFlexibleContactRequest(payload.signers[0]),
            roles,
            fieldId: signer.email,
            formFieldWhatsappId: signer.phoneNumber,
            label: signer.label,
            provideEvidences: signerAuths.map(snakeCase) || [],
            refusable: signer?.features?.refusable,
          },
        ],
      };
    },
    SPREADSHEET_SIGNER(payload) {
      const signers = _.get(payload, 'signers') || [];
      const signAsList = _.get(payload, 'signAsList');
      const signerPropsToOmit = ['context', 'auths', 'additionalAuths'];
      const spreadsheetFlowLists = _.reduce(
        signAsList,
        (lists, signAsOptions, description) => {
          const signer = _.find(signers, { description });
          signAsOptions
            .map((signAs) => _.omit(_.assign(signer, { signAs }), signerPropsToOmit))
            .forEach((list) => lists.push(list));
          return lists;
        },
        []
      );
      return { spreadsheetFlowLists };
    },
  };

  const signAsFormBuilder = contextBuilders[context] || contextBuilders.DEFAULT;
  return signAsFormBuilder(_.cloneDeep(signAsForm));
};
