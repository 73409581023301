import { axios } from '@/plugins';

const signinSaml = async ({ getters, commit }, payload) => {
  try {
    const requestUrl = _.get(getters, 'getLinks.oauth2.saml');
    const { data } = await axios.post(requestUrl, payload);
    commit('SET_LINKS', { samlUrl: data.url });
  } catch (err) {
    throw err;
  }
};

const signin = async ({ getters, commit }, { payload, requestHeaders }) => {
  try {
    const requestUrl = _.get(getters, 'getLinks.signin');
    const { data, headers } = await axios.post(requestUrl, payload, { headers: requestHeaders });
    commit('SET_LINKS', data.links);
    localStorage.setItem('auth.jwt', headers.authorization);
  } catch (err) {
    throw err;
  }
};

const signout = async ({ rootGetters }) => {
  try {
    if (!localStorage.getItem('auth.jwt')) return;
    const requestUrl = rootGetters['info/getRoutes'].apiLogout;
    axios.delete(requestUrl, {
      headers: { authorization: localStorage.getItem('auth.jwt') },
    });
    localStorage.removeItem('auth.jwt');
    localStorage.removeItem('account');
  } catch (err) {
    throw err;
  }
};

const multiFactorAuth = async ({ getters, commit }, payload) => {
  try {
    const requestUrl = getters?.getLinks?.self;
    const { data } = await axios.post(requestUrl, { mfa: payload });
    commit('SET_LINKS', data.links);
  } catch (err) {
    throw err;
  }
};

const getMultiFactorSettings = async ({ getters, commit }) => {
  try {
    const requestUrl = getters?.getLinks?.self;
    const { data } = await axios.get(requestUrl);
    commit('SET_LINKS', data.links);
  } catch (err) {
    throw err;
  }
};

const requestSMSCode = async ({ getters }) => {
  try {
    const requestUrl = getters?.getLinks?.smsPath;
    await axios.get(requestUrl);
  } catch (err) {
    throw err;
  }
};

const mfaDisable = async ({ getters, commit }) => {
  try {
    const requestUrl = getters?.getLinks?.self;
    const { data } = await axios.get(requestUrl);
    commit('SET_LINKS', data.links);
  } catch (err) {
    throw err;
  }
};

const terms = async ({ getters, commit }) => {
  try {
    const requestUrl = getters?.getLinks?.self;
    const { data } = await axios.post(requestUrl);
    commit('SET_LINKS', data.links);
  } catch (err) {
    throw err;
  }
};

const signup = async ({ getters, commit }, payload) => {
  try {
    const requestUrl = _.get(getters, 'getLinks.signup');
    const { accessTermEnabled } = payload;
    const { data } = await axios.post(requestUrl, { user: payload, accessTermEnabled });
    commit('SET_USER', data.user);
    commit('SET_LINKS', data.links);
  } catch (err) {
    throw err;
  }
};

const recoverPassword = async ({ getters }, payload) => {
  try {
    const requestUrl = _.get(getters, 'getLinks.self');
    await axios.post(requestUrl, payload);
  } catch (err) {
    throw err;
  }
};

const resetPassword = async ({ getters }, payload) => {
  try {
    const requestUrl = _.get(getters, 'getLinks.self');
    await axios.patch(requestUrl, payload);
  } catch (err) {
    throw err;
  }
};

const fetchAdvertisement = async ({ getters, commit }, payload) => {
  try {
    const requestUrl = getters?.getLinks?.advertisements;
    const { data } = await axios.get(requestUrl, { params: { name: payload } });
    commit('SET_ADVERTISEMENT', data);
  } catch (err) {
    throw err;
  }
};

const fetchSignupData = async () => {
  try {
    return await axios.get(window.location.href);
  } catch (err) {
    throw err;
  }
};

export default {
  signinSaml,
  signin,
  signout,
  multiFactorAuth,
  terms,
  signup,
  recoverPassword,
  resetPassword,
  mfaDisable,
  requestSMSCode,
  getMultiFactorSettings,
  fetchAdvertisement,
  fetchSignupData,
};
