const billing = {
  tip: {
    text: 'Solo los administradores de la cuenta y usuarios con perfiles financieros pueden visualizar esta pestaña.',
    // TOGGLE_MIDAS_SCOPE
    // TODO: remove textOld when scope > 3
    textOld: 'El administrador de la cuenta puede conceder acceso a la pestaña de facturación',
    link: 'Editar para habilitar',
    // TOGGLE_MIDAS_SCOPE
    // TODO: remove linkOld when scope > 3
    linkOld: 'Editar un usuario',
    description: 'el acceso de otros usuarios',
    // TOGGLE_MIDAS_SCOPE
    // TODO: remove descriptionOld when scope > 3
    descriptionOld: 'de la cuenta para habilitar el acceso',
    disabledLink: 'Disponible solo para administradores',
    disabledAlert:
      'Pestaña disponible {0}. Solo los administradores de la cuenta pueden hacer modificaciones.',
    onlyForView: 'solo para visualización',
  },
  vindiErrors: {
    title: 'Nada que mostrar, por ahora.',
    message: {
      processing: 'Aún estamos procesando la información de esta pestaña.',
      waiting: 'Espera unos minutos e intenta nuevamente o ',
      talkToSupport: 'habla con nuestro equipo de soporte',
    },
    button: {
      back: 'Regresar',
    },
  },
  products: {
    sms: 'Token vía SMS',
    whatsapp: 'Token vía WhatsApp',
    document: 'Documentos firmados',
    acceptanceTermWhatsapp: 'Aceptar vía WhatsApp',
    timestamp: 'Marca de tiempo',
    biometricsAuthentication: 'Biometria SERPRO',
    documentscopyAuthentication: 'Documentoscopia',
    dynamicSelfieAuthentication: 'Selfie dinámica',
    facialBiometrics: 'Biometría facial',
  },
  negotiation: {
    products: {
      additionalDocuments: 'Documentos adicionales',
      sms: 'SMS',
      whatsapp: 'WhatsApp',
      document: 'Documentos firmados',
      acceptanceTermWhatsapp: 'Aceptar vía WhatsApp',
      additionalAcceptanceTermWhatsapp: 'Aceptar vía WhatsApp adicional',
      timestamp: 'Marca de tiempo',
      biometricsAuthentication: 'Biometria SERPRO',
      documentscopyAuthentication: 'Documentoscopia',
      dynamicSelfieAuthentication: 'Selfie dinámica',
      facialBiometrics: 'Biometría facial',
    },
  },
  chargeInfo: {
    title: 'Información de pago',
    cpf: 'CPF: {cpf}',
    cnpj: 'CNPJ: {cnpj}',
    name: 'Nombre: {name}',
    tradingName: 'Razón Social: {tradingName}',
    email: 'Correo de facturación: {email}',
    cep: 'CEP: {cep}',
    change: 'Modificar datos',
  },
  dueDay: {
    info: 'Vencimiento de la factura el día {dueDay}',
    change: 'Cambiar fecha de vencimiento',
  },
  paymentProfile: {
    title: 'Facturación',
    label: '{creditCardFlag} - Final **{creditCardLastFourDigits}',
    change: 'Cambiar',
    bankSlip: 'Boleto',
    changePaymentMethod: 'Cambiar método de pago',
    tooltip: {
      title: 'El pago con tarjeta se confirma de inmediato',
      description: 'Agrega una tarjeta de crédito para evitar retrasos y el bloqueo de la cuenta.',
      ignore: 'Ignorar',
      addCreditCard: 'Agregar',
    },
  },
  plan: {
    title: 'Mi plan',
    helpLink: 'Conoce tu consumo',
    // TOGGLE_MIDAS_SCOPE
    // TODO: remove titleOld when scope > 3
    titleOld: 'Plan',
    changePlan: 'Cambiar plan',
    stepperPaths: {
      title: 'Plan y facturación',
      choose: 'Escoger',
      change: 'Cambiar método de pago',
      add: 'Agregar',
      confirm: 'Confirmación',
    },
    extraResources: 'Recursos extra',
    consumption: {
      understandingYourConsumption: 'Conoce tu consumo',
      current: 'Consumo del plan',
      title: 'Consumo actual',
      period: 'Período: {start} a {end}.',
      description: 'Conoce',
      link: 'cómo se contabiliza el consumo.',
      monthly: 'mensual',
      yearly: 'anual',
      accounting: 'Contabilización {0}. Período actual: {1} a {2}.',
    },
    accountingYearly: {
      users: {
        total: '{0} usuarios utilizados',
        description: 'Usuarios ilimitados',
      },
      document: {
        total: '{0} documentos utilizados',
        description: '{0} documentos contratados',
      },
      acceptanceTermWhatsapp: {
        total: '{0} Aceptaciones vía Whatsapp utilizadas',
        description: '{0} Aceptaciones contratados vía Whatsapp',
      },
    },
    list: {
      signature: {
        title: 'Elige tu nuevo plan Clicksign Firma',
        description: 'Elige, cambia o cancela tu plan en cualquier momento, sin penalizaciones',
      },
      custom: {
        title: 'Planes personalizados',
        description:
          'Conoce nuestras soluciones personalizadas para mejorar el rendimiento de tu negocio',
      },
      tableTitle: 'Compara los planes',
    },
    confirmChange: {
      title: 'Confirma el cambio de plan',
      current: 'Plan actual',
      new: 'Nuevo plan',
      consumption: {
        title: 'Tu consumo',
        usage: 'Has usado {0} de los {1} días contratados en el plan actual.',
        link: 'Conoce cómo funciona nuestro cálculo.',
      },
    },
  },
  settings: {
    title: 'Configuraciones de facturación',
    changeInfo: 'Cambiar información de facturación',
    changeDueDay: 'Cambiar día de vencimiento',
    changePaymentMethod: 'Cambiar método de pago',
  },
  payment: {
    title: 'Cambiar método de pago',
    header: 'Métodos de pago registrados',
    card: 'Tarjeta {name} final ({number})',
    expired: 'Vencida',
    bankSlip: 'Boleto',
    same: 'Selecciona un método de pago diferente al actual',
    changeSuccess: 'Método de pago cambiado con éxito',
    deleteModal: {
      title: 'Eliminar tarjeta',
      description: '¿Estás seguro de que deseas eliminar la tarjeta con final ({0})?',
      actions: {
        delete: 'Eliminar',
        cancel: 'Cancelar',
      },
    },
    addCard: {
      title: 'Agregar tarjeta',
      description: 'Ingresa los datos de pago',
      cardNumber: 'Número de la tarjeta',
      cardName: 'Nombre impreso en la tarjeta',
      expireDate: 'Fecha de vencimiento',
      cvv: 'CVV',
      cvvTooltip: 'Código de 3 dígitos en el reverso de la tarjeta',
      actions: {
        add: 'Agregar',
      },
      success: 'Tarjeta añadida con éxito',
      error: 'Algo salió mal, contacta con',
    },
    deleteCard: {
      success: 'Tarjeta eliminada con éxito',
    },
    confirm: {
      title: 'Confirma el nuevo método de pago',
      description: 'El cambio se aplicará solo en el próximo período de facturación.',
      review: 'Revisa la información antes de confirmar',
      methods: {
        current: 'Método de pago actual',
        new: 'Nuevo método de pago',
      },
    },
    actions: {
      addCard: 'Agregar tarjeta',
      save: 'Guardar',
    },
  },
  cancelPlan: {
    title: 'Solicitar cancelación',
  },
  cancelAccount: {
    title: 'Cancelar cuenta',
    request: 'Solicitar cancelación',
  },
  info: {
    form: {
      info: 'Datos del cliente',
      address: 'Dirección de facturación',
    },
  },
  trial: {
    period: 'Cuenta en período de prueba hasta {date}',
    remaining: {
      title: 'Saldo restante del período gratuito:',
      documents: '{documents} envíos de documentos',
      acceptance: '{acceptance} envíos de aceptación',
      noCharge: 'No se generarán cargos automáticos.',
      charge: 'Saldo disponible solo durante el período de prueba.',
    },
    // TOGGLE_MIDAS_SCOPE: 3
    // TODO: remove title
    title: 'Cuenta en período de prueba',
    link: 'Contrata un plan',
    description:
      'Podrás aprovechar los {0} envíos gratuitos de tu período de prueba hasta {1}. Al final de este período, las funcionalidades serán bloqueadas. No te preocupes, {2}',
    emphasis: 'no se generarán cargos automáticos.',
    ending: 'Tu período de prueba {2}',
    endingEmphasis: 'ha finalizado.',
    hiring:
      'Contrata un plan para seguir enviando documentos de forma sencilla, rápida y con validez legal.',
  },
  changeDueDay: {
    pickADay: {
      title: 'Elige un nuevo día de vencimiento',
      period: 'Período de facturación actual: {start} a {end}.',
      description:
        'Los cargos pendientes en sábado, domingo o días festivos se extenderán al primer día hábil disponible..',
      placeholder: 'Selecciona una fecha...',
      datePicker: 'Día ',
    },
    confirmDate: {
      title: 'Confirma los cambios',
      current: 'Fecha de pago actual',
      new: 'Nueva fecha de pago ',
      everyDay: 'Cada día {0}',
      description: 'El cambio se aplicará solo en el próximo período de facturación.',
      alert: 'Revisa la información antes de confirmar.',
      success: 'El día de vencimiento se ha cambiado con éxito',
      error: 'Límite de cambio excedido',
      errorText: 'Límite de cambio excedido, contacta con nuestra',
      linkText: 'central de soporte',
      helpCenter: 'centro de ayuda',
    },
  },
  billingAlert: {
    pending: {
      description: 'Tu factura ya está disponible para pago. {0}',
      action: 'Revisa la pestaña Plan y Facturación en la sección Configuraciones.',
    },
    overdueToday: {
      description: 'Tu factura vence hoy. {0} para garantizar tu acceso a la cuenta.',
      action: 'Realiza el pago',
    },
    overdue: {
      desktopTitle: 'Pago no identificado. Tu acceso será bloqueado en {days} días. ',
      mobileTitle: 'Pago no identificado.',
      description: 'Si el pago ya fue realizado, {0}.',
      action: 'haz clic aquí para enviar tu comprobante',
      remainingDaysToBlock: '{days} días | {days} día | {days} días',
    },
    action: 'Pagar factura',
  },
  trustWindowAlert: {
    pending: {
      title: 'Estamos verificando el pago de tu factura',
      description: 'Mientras tanto, puedes seguir usando Clicksign!',
    },
    pendingMobile: {
      title: 'Verificando el pago de tu factura',
      description: 'Mientras tanto, sigue usando Clicksign!',
    },
    lastDay: {
      title: 'Pago aún no identificado. Tu acceso será bloqueado el {date}.',
      action: 'Pagar factura',
      description: 'Si el pago ya fue realizado, {0}.',
      paymentVoucher: 'haz clic aquí para enviar tu comprobante',
    },
    lastDayMobile: {
      title: 'Pago no identificado',
    },
  },
  invoiceOverdueModal: {
    title: 'Paga la última factura para garantizar tu acceso',
    description: 'Si el pago ya ha sido realizado, puedes ignorar este mensaje.',
    sendVoucher: 'haz clic aquí para enviar el comprobante.',
    payInvoice: 'Pagar factura',
  },
  invoiceDueTodayModal: {
    title: 'La factura de tu cuenta vence hoy',
    description:
      'Sugerencia: para mantener el pago siempre al día y evitar el bloqueo de tu acceso, paga con tarjeta de crédito.',
    seeInvoice: 'Ver factura',
    addCreditCard: 'Registrar tarjeta',
  },
};

export default billing;
