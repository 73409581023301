const voiceBiometrics = {
  allowVoice: {
    title: 'Permita acesso ao microfone',
    description: 'Este é um requisito de segurança deste processo de assinatura.',
    next: 'Avançar',
    error: 'Ative o microfone em seu navegador',
    help: 'Saiba mais',
  },
  captureVoice: {
    helpTitle: 'Biometria de voz',
    helpDescription: 'Grave sua voz para fazermos o reconhecimento com base no nosso cadastro.',
    title: 'Clique no botão e fale a frase a seguir:',
    phrase: '"Sou reconhecido pela minha voz"',
    sending: 'Enviando áudio para reconhecimento...',
    recording: 'Gravando...',
    helpLink: 'Saiba mais',
  },
  feedback: {
    success: {
      title: 'Voz reconhecida com sucesso!',
    },
    error: {
      title: 'Ainda não reconhecemos sua voz',
    },
    nextButton: 'Avançar',
    tryAgainButton: 'Tentar novamente',
  },
};

export default voiceBiometrics;
