<script setup>
import { computed, onMounted, ref } from 'vue';

import {
  useFunctionalities,
  useListenForGaEvents,
  useRoute,
  useToast,
  useToggle,
} from '@base/hooks';
import { FEATURES } from '@base/constants';
import { useStore } from '@/store';
import { useI18n } from '@/locales';

// TOGGLE_NEW_LISTING_FLOW_ENABLED
// TODO: remove import line below
import FormFlowListContainerOld from '@flow/containers/form-flow/FormFlowListContainerOld/FormFlowListContainerOld.vue';
import FlowDisabledContainer from '@flow/containers/disabled/FlowDisabledContainer.vue';
import { FormFlowListHeader, FormFlowTableList } from '@flow/components/form-flow';
import { useFormFlowListActions } from '@flow/composables/useFormFlowListActions';

const FORM_FLOW_LIST_FLOWS = 'formFlowListFlows';

const { isEnabled } = useFunctionalities();
const { handleNew } = useFormFlowListActions();
const store = useStore();
const route = useRoute();
const { t } = useI18n();
const toast = useToast();

// TOGGLE_NEW_LISTING_FLOW_ENABLED
// TODO: remove loading
const loading = ref(true);

// TOGGLE_FLOW_FOLDER_ENABLED
// TODO: remove toggleFlowFolderEnabled
const { isEnabled: toggleFlowFolderEnabled } = useToggle('toggle_flow_folder_enabled');

const root = ref();

useListenForGaEvents(root, 'automation-form-flows-app');

const tabItems = [
  { title: t('flowModule.list.tab.flow'), name: 'flow', to: { name: FORM_FLOW_LIST_FLOWS } },
  {
    title: t('flowModule.list.tab.folders'),
    name: 'folder',
    to: { name: 'formFlowListFolders' },
  },
];

const isFormFlowEnabled = computed(() => isEnabled(FEATURES.FORM_FLOW));

const fetchFormFlows = async () => {
  try {
    loading.value = true;
    store.commit('formFlow/SET_LINKS', { self: window.location.href });
    await store.dispatch('formFlow/fetchFormFlows');
  } catch (err) {
    toast.error(t('flowModule.list.error'));
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  // TOGGLE_FLOW_FOLDER_ENABLED
  // TODO: Remove if/else condition and keep if content
  if (toggleFlowFolderEnabled.value) {
    if (route.name === FORM_FLOW_LIST_FLOWS) {
      fetchFormFlows();
    }
  } else {
    fetchFormFlows();
  }
});
</script>

<template>
  <main>
    <template v-if="isFormFlowEnabled">
      <!-- 
        TOGGLE_NEW_LISTING_FLOW_ENABLED
        TODO: remove FeatureFlag and keep template default
      -->
      <FeatureFlag name="toggleNewListingFlowEnabled">
        <template #default>
          <section
            ref="root"
            :class="$style.wrapper"
          >
            <FormFlowListHeader
              data-testid="formFlowListHeader"
              @handle-new="handleNew"
            />
            <!--
              TOGGLE_FLOW_FOLDER_ENABLED
              TODO: remove feature flag and keep template default
            -->
            <FeatureFlag name="toggleFlowFolderEnabled">
              <template #default>
                <div>
                  <XTabs
                    :items="tabItems"
                    :active-tab="$route.name === 'formFlowListFolders' ? 'folder' : 'flow'"
                    :class="$style.tabs"
                  />
                  <router-view />
                </div>
              </template>
              <template #off>
                <FormFlowTableList />
              </template>
            </FeatureFlag>
          </section>
        </template>
        <template #off>
          <FormFlowListContainerOld
            :loading="loading"
            data-testid="formFlowListContainer"
          />
        </template>
      </FeatureFlag>
    </template>
    <FlowDisabledContainer
      v-else
      type="default"
      data-testid="flowDisabledContainer"
    />
  </main>
</template>

<style lang="scss" module>
.tabs {
  width: 100%;
}

.wrapper {
  margin: var(--space-large);
}
</style>
