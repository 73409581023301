const downloads = {
  title: 'Documentos baixados',
  description: 'Acompanhe aqui suas solicitações para baixar',
  backupInfo: {
    description: 'Admins podem fazer o backup de todos os documentos da conta.',
    tip: 'Para fazer mais que 3 backups, aguarde até que algum deles expire.',
    tipLimit: 'Limite de backups atingido. Aguarde até que algum deles expire.',
    backupLimit: 'Backups restantes',
    backup: 'Fazer Backup',
  },
  table: {
    date: 'Data',
    email: 'E-mail do usuário',
    type: 'Tipo do arquivo',
    status: 'Status',
    requester: 'Solicitante',
    item: 'Item',
    statuses: {
      ready: 'Baixar',
      error: 'Erro',
      expired: 'Expirado',
      downloaded: 'Baixado em',
      processing: 'Processando',
    },
    tips: {
      ready: 'O link para baixar ficará disponível até essa data limite',
      error: 'Ocorreu um erro, tente  baixar novamente',
      expired: 'Para segurança dos documentos, o link para baixar expirou',
      processing: 'Quando os arquivos estiverem prontos, você poderá baixá-los aqui',
    },
    until: 'Até {0}',
    at: 'Em {0}',
    empty: {
      title: 'Nada baixado por enquanto',
      description: 'Você poderá acompanhar o histórico de downloads aqui!',
    },
  },
  modalDetails: {
    title: 'Detalhes',
    description: 'Confira os arquivos compactados para baixar',
    nodes: 'Documento e pasta',
    backup: 'Backup de documentos',
  },
  modalListBackup: {
    title: 'Baixar Arquivos',
    description: 'Clique para baixar os arquivos compactados',
  },
};

export default downloads;
