export const FORM_FLOW_MODAL_CONSTANTS = {
  formFlowEditEnabledModal: 'FormFlowEditEnabledModal',
  formFlowCopyLinkModal: 'FormFlowCopyLinkModal',
  formFlowDisableModal: 'FormFlowDisableModal',
  formFlowPrivacyModal: 'FormFlowPrivacyModal',
  flowDeleteModal: 'FlowDeleteModal',
  formFlowBulkActionDisableModal: 'FormFlowBulkActionDisableModal',
  formFlowBulkActionDeleteModal: 'FormFlowBulkActionDeleteModal',
  formFlowBulkActionDuplicateModal: 'FormFlowBulkActionDuplicateModal',
  formFlowPendingProcessesModal: 'FormFlowPendingProcessesModal',
  flowAlertModal: 'FlowAlertModal',
  moveFlowsToFolderModal: 'MoveFlowsToFolderModal',
  formFlowCreateFolderModal: 'FormFlowCreateFolderModal',
  removeFlowsFromFolderModal: 'RemoveFlowsFromFolderModal',
};
