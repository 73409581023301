import { computed, ref, watch } from 'vue';
import { useI18n } from '@/locales';
import { useStore } from '@/store';
import localStorage from '@base/hooks/useLocalStorage';
import { VIEW_OPTIONS_CONSTANTS } from '@flow/constants';

export function useFormFlowViewToggle() {
  const { t } = useI18n();
  const store = useStore();
  // TODO: get initial value from backend
  const defaultInitialValue = VIEW_OPTIONS_CONSTANTS.list;

  const viewOptions = [
    { icon: 'tasks', text: t('flowModule.view.list'), value: VIEW_OPTIONS_CONSTANTS.list },
    { icon: 'grip-vertical', text: t('flowModule.view.grid'), value: VIEW_OPTIONS_CONSTANTS.grid },
  ];
  const account = computed(() => store.getters['info/getAccount']);
  const keyName = computed(() => `FORM_FLOW_VIEW_OPTION_${account.value.id}`);

  const saveSelectedViewOption = (viewOption) => {
    localStorage.setLocalStorageKey(
      keyName.value,
      JSON.stringify({
        value: viewOption,
        updatedAt: new Date(),
      })
    );
  };

  const getLastSelectedViewOption = () => {
    return localStorage.getLocalStorageKey(keyName.value)?.value;
  };

  const getInitValue = () => {
    const lastValue = getLastSelectedViewOption();

    return lastValue ?? defaultInitialValue;
  };

  const currentView = ref(getInitValue());

  watch(currentView, saveSelectedViewOption);

  return {
    currentView,
    viewOptions,
  };
}
