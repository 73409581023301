<script setup>
// TOGGLE_SEARCH_PENDING_ANSWERS_ENABLED
// TODO: Remove script setup
import { computed, onMounted, ref } from 'vue';
import { useStore } from '@/store';
import { useRouter, useRoute, useGA, useToggle } from '@base/hooks';
import { isEqual } from 'lodash';

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});
const store = useStore();
const router = useRouter();
const route = useRoute();
const { isEnabled: toggleSearchPendingAnswersEnabled } = useToggle(
  'toggle_search_pending_answers_enabled'
);
const filterValue = ref([]);
const flowsFilter = computed(() => store.getters['pendingAnswers/getFlowsFilter']);
const filterItems = computed(() => {
  return flowsFilter.value.map(({ name, id }) => ({ text: name, value: id }));
});
const flowFormIdParam = computed(() => route.query.flow_form_id || route.query.flowFormId || []);
const flowFormIdNumber = computed(() => flowFormIdParam.value.map((item) => +item));
const hasFilterSelected = computed(() => filterValue.value.length > 0);
const filterHasNotChanged = computed(() => isEqual(flowFormIdNumber.value, filterValue.value));
const updateFilterRouter = () => {
  useGA('event', 'form-filter-click', 'automation-forms-pendencies-app');
  router.push({ query: { flowFormId: filterValue.value } });
};
const clearFilter = () => {
  filterValue.value = [];
  if (flowFormIdParam.value.length > 0) {
    router.push({ query: {} });
  }
};
onMounted(() => {
  filterValue.value = flowFormIdNumber.value;
});
</script>

<template>
  <header :class="$style.header">
    <h1 :class="$style.title">
      {{ $t('pendingAnswers.header.title') }}
    </h1>
    <XAlert
      :class="$style.alert"
      type="info"
      icon="info-circle"
      full-width
    >
      {{ $t('pendingAnswers.header.alert') }}
    </XAlert>
    <!-- TOGGLE_SEARCH_PENDING_ANSWERS_ENABLED -->
    <!-- TODO: Remove div element below and all content inside -->
    <diV v-if="!toggleSearchPendingAnswersEnabled">
      <XSkeletonLoader
        v-if="loading"
        shape="rounded"
        width="100%"
        height="85px"
        data-testid="skeletonLoader"
      />
      <div
        v-else
        :class="$style.filterContainer"
      >
        <XFormField
          :label="$t('pendingAnswers.header.filter.label')"
          flexible
        >
          <div :class="$style.filter">
            <XSelect
              v-model="filterValue"
              multiple
              full-width
              :disabled="!flowsFilter.length"
              :placeholder="$t('pendingAnswers.header.filter.placeholder')"
              :items="filterItems"
              data-testid="filterFlow"
            />
          </div>
          <XButton
            design="text"
            size="sm"
            :disabled="!hasFilterSelected"
            data-testid="clearFilterButton"
            @click="clearFilter"
          >
            {{ $t('pendingAnswers.header.filter.clear') }}
          </XButton>
          <XButton
            design="outlined"
            size="sm"
            :disabled="filterHasNotChanged"
            data-testid="updateFilterButton"
            @click="updateFilterRouter"
          >
            {{ $t('pendingAnswers.header.filter.button') }}
          </XButton>
        </XFormField>
      </div>
    </diV>
  </header>
</template>

<style lang="scss" module>
.header {
  .alert {
    margin-bottom: var(--space-regular);
  }

  .title {
    color: var(--color-brand-primary-600);
  }

  // TOGGLE_SEARCH_PENDING_ANSWERS_ENABLED

  // TODO: Remove class '.filterContainer'

  .filterContainer {
    margin-bottom: var(--space-medium);
    padding: var(--space-small-xx);
    background-color: var(--color-neutral-50);

    .filter {
      width: 303px;
    }
  }
}
</style>
