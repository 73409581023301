const voiceBiometrics = {
  allowVoice: {
    title: 'Permite acceso al micrófono',
    description: 'Este es un requisito de seguridad de este proceso de firma.',
    next: 'Avanzar',
    error: 'Activa el micrófono en tu navegador',
    help: 'Más información',
  },
  captureVoice: {
    helpTitle: 'Biometría de voz',
    helpDescription:
      'Graba tu voz para que podamos hacer el reconocimiento con base en nuestro registro.',
    title: 'Haz click en el botón y di la siguiente frase:',
    phrase: '"Soy reconocido por mi voz"',
    sending: 'Enviando audio para reconocimiento...',
    recording: 'Grabando...',
    helpLink: 'Conoce más',
  },
  feedback: {
    success: {
      title: '¡Voz reconocida con éxito!',
    },
    error: {
      title: 'Aún no hemos reconocido tu voz',
    },
    nextButton: 'Avanzar',
    tryAgainButton: 'Intentar de nuevo',
  },
};

export default voiceBiometrics;
